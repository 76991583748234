var is_deploy = false;
var type = "pwa";

var base_url_dashboard = is_deploy
    ? "https://store.squiseat.com"
    : "https://dev-store.squiseat.com/";
var base_Api_url_dashboard = is_deploy
    ? "https://squiseat.com/SquisEatWeb"
    : "https://dev.squiseat.com/SquisEatWeb";

const chatID_Costumer_locali = -1001184627087;

const axiosHeaders = {
    baseURL: base_Api_url_dashboard,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};

var firebaseConfigAppLocali = {
    apiKey: "AIzaSyARcLodRG59vL6YvFI28f_bA6-bze6wDbQ",
    authDomain: "dashboard-ristoratori.firebaseapp.com",
    databaseURL: "https://dashboard-ristoratori.firebaseio.com",
    projectId: "dashboard-ristoratori",
    storageBucket: "dashboard-ristoratori.appspot.com",
    messagingSenderId: "310345258022",
    appId: "1:310345258022:web:4cdf93faafb3ef263282e7",
    measurementId: "G-H0X642BSSJ",
};

var firebaseConfigAppClienti = {
    apiKey: "AIzaSyAGQOD4SxQdcUr1ty0qGQA0bxJFfa__z5o",
    authDomain: "squiseatbot.firebaseapp.com",
    databaseURL: "https://squiseatbot.firebaseio.com",
    projectId: "squiseatbot",
    storageBucket: "squiseatbot.appspot.com",
    messagingSenderId: "744844358996",
    appId: "1:744844358996:web:b789c7e8c996ced1d104c1",
    measurementId: "G-45BYZBG7FX",
};

var sentry = {
    dns: "https://d6dee833ca6544438ac1400244d9e82a@o504322.ingest.sentry.io/5590998",
};

const CACHE_FOLDER = "CACHED_IMG";

var config = {
    isDeploy: is_deploy,
    ApiUrl: base_Api_url_dashboard,
    HomeUrl: base_url_dashboard,
    axiosHeaders: axiosHeaders,
    firebaseConfig: {
        firebaseConfigAppLocali: firebaseConfigAppLocali,
        firebaseConfigAppClienti: firebaseConfigAppClienti,
    },
    chatIDCostumer: chatID_Costumer_locali,
    type: type,
    sentry: sentry,
    cacheFolder: CACHE_FOLDER,
};

module.exports = config;
