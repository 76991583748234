import React, { useContext, useState } from "react";
import { Div, Text, Row, Col, Button } from "atomize";
import { authContext } from "../../providers/auth.provider";
import { useEffect } from "react";
import InlineCheckboxButtons from "../inlineCheckboxButtons/InlineCheckboxButtons";
import moment from "moment";
import BoldText from "../boldText/BoldText";
import { getDayNameFromNum, getItaDayNameFromEng } from "../../services/func.utils";
import theme from "../../appearance/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import UndoToast from "../toast/UndoToast";
import TimeInput from "../atoms/TimeInput";
import { sendMessageToCustomerGroup } from "../../services/api.service";
import { addDeliveryHourToBeConfirmedListener, setDeliveryHourToBeConfirmed } from "../../services/firebase.service";

function EditOpenHourModal(props) {
    const [day, setDay] = useState((((moment().day() - 1) % 7) + 7) % 7);
    const [openHours, setOpenHours] = useState({});
    const [initialPickUpHours, setInitialPickUpHours] = useState("");
    const [editHourStep, setEditStep] = useState(0);
    const [openHourSummary, setOpenHourSummary] = useState(null);

    const { supplierData, setSupplierData } = useContext(authContext);

    const days = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];

    let undoToast = false;
    const setUndoState = (val) => {
        undoToast = val;
    };

    useEffect(() => {
        let supplierPickUpHours = {};
        supplierData &&
            supplierData.pickUpTimeTable.forEach((pickUpTime, index) => {
                let deliveryHours = {
                    deliveryHourStart:
                        pickUpTime.deliveryHourStart.length === 5
                            ? pickUpTime.deliveryHourStart
                            : `0${pickUpTime.deliveryHourStart}`,
                    deliveryHourEnd:
                        pickUpTime.deliveryHourEnd.length === 5
                            ? pickUpTime.deliveryHourEnd
                            : `0${pickUpTime.deliveryHourEnd}`,
                    order: index,
                };
                supplierPickUpHours[pickUpTime.dayofweek]
                    ? supplierPickUpHours[pickUpTime.dayofweek].push(deliveryHours)
                    : (supplierPickUpHours[pickUpTime.dayofweek] = [deliveryHours]);
            });
        setOpenHours(supplierPickUpHours);
        setInitialPickUpHours(JSON.stringify(supplierPickUpHours));
        scrollDays(moment().day());
    }, []);

    const updatePickUpHour = (value, pickUpHourIndex, deliveryHourType) => {
        let newPickUpHours = openHours[getDayNameFromNum(day)];
        newPickUpHours[pickUpHourIndex][deliveryHourType] = value;
        setOpenHours((prevState) => ({
            ...prevState,
            [getDayNameFromNum(day)]: newPickUpHours,
        }));
    };

    const showFirstAddButton = () => {
        let firstDeliveryHourStart = openHours[getDayNameFromNum(day)]
            ? openHours[getDayNameFromNum(day)][0]?.deliveryHourStart?.split(":")
            : null;
        if (!firstDeliveryHourStart) return true;
        else {
            let newDeliveryHourStart = moment()
                .set({
                    hour: parseInt(firstDeliveryHourStart[0]),
                    minutes: parseInt(firstDeliveryHourStart[1]),
                })
                .subtract(2, "hour");

            firstDeliveryHourStart = moment().set({
                hour: parseInt(firstDeliveryHourStart[0]),
                minutes: parseInt(firstDeliveryHourStart[1]),
            });
            return !moment(firstDeliveryHourStart).isAfter(moment(newDeliveryHourStart), "day");
        }
    };

    const showNextAddButton = (pickUpHour) => {
        let nextDeliveryHourStart = openHours[getDayNameFromNum(day)][pickUpHour + 1]?.deliveryHourStart?.split(":");
        let newDeliveryHourEnd = openHours[getDayNameFromNum(day)][pickUpHour]?.deliveryHourEnd?.split(":");
        newDeliveryHourEnd = moment()
            .set({
                hour: parseInt(newDeliveryHourEnd[0]),
                minutes: parseInt(newDeliveryHourEnd[1]),
            })
            .add(2, "hour");

        if (!nextDeliveryHourStart)
            return !moment(newDeliveryHourEnd).subtract(2, "hour").isBefore(moment(newDeliveryHourEnd), "day");
        else {
            nextDeliveryHourStart = moment().set({
                hour: parseInt(nextDeliveryHourStart[0]),
                minutes: parseInt(nextDeliveryHourStart[1]),
            });
            return moment(newDeliveryHourEnd).isBefore(moment(nextDeliveryHourStart));
        }
    };

    const getMinValue = (pickUpHour) => {
        let min = openHours[getDayNameFromNum(day)][pickUpHour - 1]?.deliveryHourEnd;
        if (min) {
            min = moment().set({ hour: min.split(":")[0], minute: min.split(":")[1] });
            min = moment(min).add(15, "minutes");
            return moment(min).format("HH:mm");
        } else return "00:00";
    };

    const getMaxValue = (pickUpHour) => {
        let max = openHours[getDayNameFromNum(day)][pickUpHour + 1]?.deliveryHourStart;
        if (max) {
            max = moment().set({ hour: max.split(":")[0], minute: max.split(":")[1] });
            max = moment(max).subtract(15, "minutes");
            return moment(max).format("HH:mm");
        } else return "23:59";
    };

    const handleSetDay = (selectedDay) => {
        scrollDays(selectedDay);
        setDay(selectedDay);
    };

    const scrollDays = (selectedDay) => {
        const wrapper = document.getElementById("inline_checkbox");
        const checkboxOffset = document.getElementById("checkbox_" + selectedDay)?.offsetWidth;
        //scrollTo("#anchor-" + index, 60, 0, 600)
        selectedDay > day
            ? wrapper.scrollTo({
                  left: wrapper.scrollLeft + checkboxOffset,
                  behavior: "smooth",
              })
            : wrapper.scrollTo({
                  left: wrapper.scrollLeft - checkboxOffset,
                  behavior: "smooth",
              });
    };

    const confirmOpenHour = async () => {
        if (editHourStep === 0) {
            let days = [];
            let hours = [];
            Object.keys(openHours).forEach((day) => {
                days.push(getItaDayNameFromEng(day));
                let dayHours = "";
                openHours[day].forEach((hour, index) => {
                    if (index > 0) dayHours += ", ";
                    dayHours += `${hour.deliveryHourStart}-${hour.deliveryHourEnd}`;
                });
                hours.push(dayHours);
            });
            if (days[0] === "Dom") {
                days.push(days.shift());
                hours.push(hours.shift());
            }
            setOpenHourSummary({ days: days, hours: hours });
            setEditStep(1);
        } else {
            let newPickUpHourMessage = "\n\b🕔 NUOVI ORARI DI RITIRO 🕔\n";
            Object.keys(openHours).forEach((day) => {
                newPickUpHourMessage += `${day}:\n`;
                openHours[day].forEach((pickUpHour) => {
                    newPickUpHourMessage += `${pickUpHour?.deliveryHourStart} - ${pickUpHour?.deliveryHourEnd}\n`;
                });
                newPickUpHourMessage += "\n";
            });
            setDeliveryHourToBeConfirmed(JSON.stringify(openHours), supplierData.supplierID.toString());
            sendMessageToCustomerGroup(null, newPickUpHourMessage).catch((error) =>
                console.error("Modifica orari di ritiro:", `${error}, ${newPickUpHourMessage}`)
            );
            props.closeModal();
            toast.success("Vedrai gli orari aggiornati il prima possibile!");
            setSupplierData((prevState) => ({
                ...prevState,
                supplierOpenHour: { ...openHourSummary, confirmed: false },
            }));
            addDeliveryHourToBeConfirmedListener(supplierData?.supplierID.toString(), (confirmed) =>
                setSupplierData((prevState) => ({
                    ...prevState,
                    supplierOpenHour: { ...prevState.supplierOpenHour, confirmed: confirmed },
                }))
            );
        }
    };

    return (
        <>
            <Div p={{ x: "1rem", y: "0.5rem" }}>
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="title" textWeight="bold">
                    Cambio orari di ritiro
                </Text>
                <Text p={{ l: "0.5rem" }}>
                    {editHourStep === 0
                        ? "Modifica come preferisci e tocca avanti!"
                        : "Tutto ok? Conferma ed entro breve le modifiche saranno effettive"}
                </Text>
                {editHourStep === 0 ? (
                    <>
                        <InlineCheckboxButtons elements={days} selectedElem={day} handleClick={handleSetDay} />
                        <Div
                            maxH="400px"
                            p={{ b: "0.5rem", l: "0.5rem" }}
                            style={{ overflowY: "auto", overflowX: "hidden" }}
                        >
                            <Div pos="relative">
                                <hr style={{ border: "1px solid #E1E4E8", margin: "1.5rem 0 1rem" }} />
                                {showFirstAddButton() && (
                                    <Button
                                        m={{ x: "auto", y: "-2rem" }}
                                        bg={theme.colors.white}
                                        textColor={theme.colors.accent}
                                        textWeight="bold"
                                        border="2px solid"
                                        borderColor={theme.colors.accent}
                                        h="2rem"
                                        rounded="20px"
                                        w="fit-content"
                                        pos="absolute"
                                        left="0"
                                        right="0"
                                        onClick={() => {
                                            let tmpPickUpHours = [];
                                            if (
                                                !openHours[getDayNameFromNum(day)] ||
                                                openHours[getDayNameFromNum(day)].length === 0
                                            )
                                                tmpPickUpHours.splice(0, 0, {
                                                    deliveryHourStart: "08:00",
                                                    deliveryHourEnd: "09:00",
                                                    order: 0,
                                                });
                                            else {
                                                tmpPickUpHours = openHours[getDayNameFromNum(day)];
                                                let newDeliveryHourStart = moment().set({
                                                    hour:
                                                        openHours[getDayNameFromNum(day)][0]?.deliveryHourStart.split(
                                                            ":"
                                                        )[0] ?? 8,
                                                    minute:
                                                        openHours[getDayNameFromNum(day)][0]?.deliveryHourStart.split(
                                                            ":"
                                                        )[1] ?? 0,
                                                });
                                                tmpPickUpHours.splice(0, 0, {
                                                    deliveryHourStart: moment(newDeliveryHourStart)
                                                        .subtract(2, "hour")
                                                        .format("HH:mm"),
                                                    deliveryHourEnd: moment(newDeliveryHourStart)
                                                        .subtract(1, "hour")
                                                        .format("HH:mm"),
                                                    order: openHours[getDayNameFromNum(day)][0]?.order - 1 ?? 0,
                                                });
                                            }

                                            setOpenHours((prevState) => ({
                                                ...prevState,
                                                [getDayNameFromNum(day)]: tmpPickUpHours,
                                            }));
                                        }}
                                    >
                                        + Aggiungi
                                    </Button>
                                )}
                            </Div>
                            {openHours[getDayNameFromNum(day)]?.map((pickUpHour, index) => (
                                <React.Fragment key={index}>
                                    <Div d="flex" justify="space-around" align="center">
                                        <Div textSize="subheader">
                                            <BoldText>Orario di inizio</BoldText>
                                            <TimeInput
                                                value={pickUpHour?.deliveryHourStart}
                                                placeholder="15:00"
                                                min={getMinValue(index)}
                                                max={pickUpHour?.deliveryHourEnd}
                                                isMinValue={true}
                                                handleChange={(value) => {
                                                    updatePickUpHour(value, index, "deliveryHourStart");
                                                }}
                                            />
                                        </Div>
                                        <Div textSize="subheader">
                                            <BoldText>Orario di fine</BoldText>
                                            <TimeInput
                                                min={pickUpHour?.deliveryHourStart}
                                                max={getMaxValue(index)}
                                                value={pickUpHour?.deliveryHourEnd}
                                                isMinValue={false}
                                                placeholder="20:30"
                                                handleChange={(value) =>
                                                    updatePickUpHour(value, index, "deliveryHourEnd")
                                                }
                                            />
                                        </Div>
                                        <Button
                                            bg="danger900"
                                            w="2.5rem"
                                            h="2.5rem"
                                            rounded="15px"
                                            onClick={() => {
                                                let tmpPickUpHours = openHours[getDayNameFromNum(day)];
                                                tmpPickUpHours[index].order = index;
                                                let rmPickUpHour = tmpPickUpHours.splice(index, 1);
                                                setOpenHours((prevState) => ({
                                                    ...prevState,
                                                    [getDayNameFromNum(day)]: tmpPickUpHours,
                                                }));
                                                toast(
                                                    <UndoToast
                                                        text="Orario eliminato"
                                                        undoAction={() => {
                                                            setUndoState(true);
                                                            toast.dismiss();
                                                        }}
                                                    />,
                                                    {
                                                        hideProgressBar: false,
                                                        onClose: () => {
                                                            if (undoToast) {
                                                                tmpPickUpHours.splice(index, 0, ...rmPickUpHour);
                                                                setOpenHours((prevState) => ({
                                                                    ...prevState,
                                                                    [getDayNameFromNum(day)]: tmpPickUpHours,
                                                                }));
                                                            }
                                                            setUndoState(false);
                                                        },
                                                    }
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} size="2x" color="white" />
                                        </Button>
                                    </Div>
                                    <Div pos="relative">
                                        <hr style={{ border: "1px solid #E1E4E8", margin: "1rem 0" }} />
                                        {showNextAddButton(index) && (
                                            <Button
                                                m={{ x: "auto", y: "-2rem" }}
                                                bg={theme.colors.white}
                                                textColor={theme.colors.accent}
                                                textWeight="bold"
                                                border="2px solid"
                                                borderColor={theme.colors.accent}
                                                h="2rem"
                                                rounded="20px"
                                                w="fit-content"
                                                pos="absolute"
                                                left="0"
                                                right="0"
                                                onClick={() => {
                                                    let tmpPickUpHours = openHours[getDayNameFromNum(day)];
                                                    let newDeliveryHourStart = openHours[getDayNameFromNum(day)][
                                                        index
                                                    ].deliveryHourEnd.split(":");

                                                    newDeliveryHourStart = moment()
                                                        .set({
                                                            hour: parseInt(newDeliveryHourStart[0]),
                                                            minutes: parseInt(newDeliveryHourStart[1]),
                                                        })
                                                        .add(1, "hour");

                                                    if (
                                                        moment(newDeliveryHourStart).isSameOrAfter(
                                                            moment().set({ hour: 0, minute: 0 })
                                                        )
                                                    )
                                                        moment(newDeliveryHourStart).set({ hour: 23, minute: 59 });

                                                    tmpPickUpHours.splice(index + 1, 0, {
                                                        deliveryHourStart: newDeliveryHourStart.format("HH:mm"),
                                                        deliveryHourEnd: newDeliveryHourStart
                                                            .add(1, "hour")
                                                            .format("HH:mm"),
                                                        active: true,
                                                    });
                                                    setOpenHours((prevState) => ({
                                                        ...prevState,
                                                        [getDayNameFromNum(day)]: tmpPickUpHours,
                                                    }));
                                                }}
                                            >
                                                + Aggiungi
                                            </Button>
                                        )}
                                    </Div>
                                </React.Fragment>
                            ))}
                        </Div>
                    </>
                ) : (
                    <Div m={{ l: "0.5rem", y: "1rem" }}>
                        {openHourSummary?.days.map((day, index) => (
                            <React.Fragment key={index}>
                                {openHourSummary?.hours[index] && (
                                    <Row key={index}>
                                        <Col size="3">
                                            <Text textSize="subheader" textWeight="bold">
                                                {day}:
                                            </Text>
                                        </Col>
                                        <Col size="8">
                                            <Text textSize="subheader">{openHourSummary?.hours[index]}</Text>
                                        </Col>
                                    </Row>
                                )}
                            </React.Fragment>
                        ))}
                    </Div>
                )}
            </Div>
            <Row m={{ x: "2rem" }}>
                <Col p="0">
                    <Button
                        onClick={() => {
                            editHourStep === 0 ? props.closeModal() : setEditStep(0);
                        }}
                        bg="white"
                        h="3.5rem"
                        m="auto"
                        textColor="gunmetal"
                    >
                        <Text textSize="title">{editHourStep === 0 ? "Annulla" : "Indietro"}</Text>
                    </Button>
                </Col>
                <Col p="0">
                    <Button
                        onClick={() => confirmOpenHour()}
                        bg="gunmetal"
                        shadow="3"
                        h="3.5rem"
                        p={{ x: "1.5rem" }}
                        rounded="20px"
                        textColor="white"
                        disabled={initialPickUpHours === JSON.stringify(openHours)}
                        m={{ x: "auto" }}
                    >
                        <Text textSize="title">{editHourStep === 0 ? "Avanti" : "Conferma"}</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default EditOpenHourModal;
