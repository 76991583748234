import { Div, Text, Row, Col, Button, Container } from "atomize";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import React, { useContext, useEffect, useState } from "react";
import apiService from "../../services/api.service";
import { toast } from "react-toastify";
import moment from "moment";
import { authContext } from "../../providers/auth.provider";
import { productsContext } from "../../providers/products.provider";
import PendingProduct from "../pendingProduct/PendingProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import theme from "../../appearance/theme";
import Card from "../atoms/Card";

function SetAvailabilitiesExpirationModal(props) {
    const [expiration, setExpiration] = useState(null); //indice che scorre le expirations
    const [expirations, setExpirations] = useState(null);
    const maxExpirations = 60;

    const [buttonDisable, setButtonDisable] = useState(false);

    const { setLog, supplierData } = useContext(authContext);
    const { editFunctions, handleProductsTypeChange, mergedProdProps } = useContext(productsContext);

    useEffect(() => {
        getNextExpiration();
    }, []);

    useEffect(() => {
        setButtonDisable(false);
    }, [props]);

    useEffect(() => {
        Object.keys(mergedProdProps.unconfirmed).length === 0 && props.closeModal();
    }, [mergedProdProps]);

    const confirmProducts = (e) => {
        e.target.disabled = true;
        setButtonDisable(true);
        handleProductsTypeChange(true);
        const d = new Date();
        const zoneOffset = -d.getTimezoneOffset() / 60;
        const date = new Date(
            Date.parse(expirations[expiration].expiringDateTime.replace(" ", "T") + "+0" + zoneOffset + ":00")
        );
        const expirationDate = date.toLocaleString("es-CL", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const expirationTime = date.toLocaleString("it-IT", {
            hour: "2-digit",
            minute: "2-digit",
        });
        editFunctions.publishAvailability(expirationDate, expirationTime, "surplus");
        props.closeModal();
    };

    const getNextExpiration = async () => {
        let param =
            !(expiration === null) && !(expirations === null)
                ? `&after_datetime=${expirations[expiration]["expiringDateTime"]}`
                : ``;
        await apiService.api
            .get(`/pickUpTime?next=1${param}` /*&limit=10"*/)
            .then((response) => {
                if (apiService.checkRedirectLogin(response.request.responseURL)) {
                    setLog(false);
                } else {
                    if (expirations === null) {
                        setExpiration(0);
                        setExpirations([response.data]);
                    } else {
                        setExpiration(expiration + 1);
                        setExpirations([...expirations, response.data]);
                    }
                }
            })
            .catch((error) => {
                console.error("[API] Get next expiration ", error);
                toast.error("C'è stato un problema ad ottenere gli orari di ritiro. MANDA UN RICHIESTA DI ASSISTENZA!");
            });
    };

    const getFormattedDate = (date) => {
        const d = new Date();
        const zoneOffset = -d.getTimezoneOffset() / 60;
        const formattedDate = new Date(Date.parse(date.replace(" ", "T") + "+0" + zoneOffset + ":00"));
        const today = moment();
        const tomorrow = moment().add(1, "days");
        if (moment(date).isSame(today, "day"))
            return (
                "Oggi, " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else if (moment(date).isSame(tomorrow, "day"))
            return (
                "Domani, " +
                formattedDate.toLocaleString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        else
            return formattedDate.toLocaleString("it-IT", {
                weekday: "short",
                month: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
    };

    return (
        <>
            <Div p={{ x: "1.5rem", t: "0.5rem" }}>
                <Text p={{ b: "0.5rem" }} textSize="title" textWeight="bold">
                    Pubblica i prodotti
                </Text>
            </Div>
            <hr style={{ border: "1px solid #E1E4E8" }} />
            <Div p={{ x: "1.5rem", t: "0.5rem" }}>
                <Container p="0">
                    <Div maxH="300px" style={{ overflowY: "auto", overflowX: "hidden" }}>
                        {mergedProdProps.unconfirmed &&
                            Object.keys(mergedProdProps.unconfirmed).map((category, catIndex) =>
                                mergedProdProps.unconfirmed[category].map((value, prodIndex) => (
                                    <Div key={value.productID}>
                                        <PendingProduct
                                            expanded={true}
                                            productInfo={value}
                                            editFunctions={editFunctions}
                                        />
                                        {!(
                                            catIndex + 1 === Object.keys(mergedProdProps.unconfirmed).length &&
                                            prodIndex + 1 === mergedProdProps.unconfirmed[category].length
                                        ) && <hr style={{ border: "1px solid #E1E4E8" }} />}
                                    </Div>
                                ))
                            )}
                    </Div>
                </Container>
            </Div>
            <hr style={{ border: "1px solid #E1E4E8" }} />
            <Div p={{ x: "1.5rem" }}>
                <Text p={{ t: "0.5rem" }} textAlign="center" textWeight="bold" textSize="subheader">
                    Fino a quando vuoi venderli?
                </Text>
                <Text textAlign="center" textSize="body">
                    Cambia la data con le frecce
                </Text>
                <Div p={{ t: "0.5rem", b: "1rem" }}>
                    {!(expirations === null) && !(expiration === null) && (
                        <Row align="center">
                            <Col size="2">
                                <Button
                                    bg="transparent"
                                    h="3rem"
                                    w="3rem"
                                    rounded="circle"
                                    textColor={expiration === 0 ? "light" : "primary"}
                                    onClick={() => {
                                        expiration > 0 && setExpiration(expiration - 1);
                                    }}
                                >
                                    <ArrowBackIosRoundedIcon fontSize="large" />
                                </Button>
                            </Col>
                            <Col size="8" textAlign="center">
                                <Text p={{ t: "0.25rem" }} textSize="title">
                                    {expirations[expiration] &&
                                        getFormattedDate(expirations[expiration].expiringDateTime)}
                                </Text>
                            </Col>
                            <Col size="2">
                                <Button
                                    bg="transparent"
                                    h="3rem"
                                    w="3rem"
                                    rounded="circle"
                                    textColor={expiration === maxExpirations ? "light" : "primary"}
                                    onClick={() => {
                                        expiration < maxExpirations &&
                                            (expirations[expiration + 1]
                                                ? setExpiration(expiration + 1)
                                                : getNextExpiration());
                                    }}
                                >
                                    <ArrowForwardIosRoundedIcon fontSize="large" />
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {supplierData?.supplierOpenHour?.confirmed === false && (
                        <Card bg={theme.colors.warning} m={{ t: "1rem" }} textAlign="center">
                            <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                            <Text textAlign="left">
                                Questi orari sono precedenti alla tua richiesta di modifica! Attendi la conferma da
                                parte del team Squiseat se vuoi utilizzare gli orari che hai richiesto
                            </Text>
                        </Card>
                    )}
                </Div>
            </Div>
            <Row m={{ t: "1rem", x: "2rem" }}>
                <Col p="0">
                    <Button onClick={props.closeModal} bg="white" h="3.5rem" textColor="gunmetal" m="auto">
                        <Text textSize="title">Indietro</Text>
                    </Button>
                </Col>
                <Col size="6">
                    <Button
                        onClick={confirmProducts}
                        disabled={buttonDisable}
                        bg="primary"
                        shadow="3"
                        w="100%"
                        h="3.5rem"
                        p={{ x: "1.5rem" }}
                        rounded="20px"
                        textColor="white"
                        m="auto"
                    >
                        <Text textSize="title">Pubblica</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default SetAvailabilitiesExpirationModal;
