import { Button, Col, Container, Div, Image, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import theme from "../../appearance/theme";
import FirebaseService from "../../services/firebase.service";
import { Browser } from "@capacitor/browser";

function Onboarding({ handleOnboard }) {
    const [state, setState] = useState(-1);

    useEffect(() => {
        const timeout = setTimeout(() => {
            state === -1 && setState(0);
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Div p={{ b: "env(safe-area-inset-bottom)" }}>
            {state === -1 ? (
                <Div
                    textAlign="center"
                    pos="fixed"
                    transition
                    p={{ t: "env(safe-area-inset-top)" }}
                    left="0"
                    bg="white"
                    w="100vw"
                    h="100vh"
                />
            ) : (
                <>
                    <Div
                        textAlign="center"
                        pos="fixed"
                        transition
                        p={{ t: "env(safe-area-inset-top)" }}
                        left="0"
                        bg="white"
                        w="100vw"
                        h="100vh"
                    >
                        <Container>
                            <Image m={{ t: "3rem" }} w="25vh" src="icon-locali.png" />
                            <Text m={{ t: "1rem" }} tag="h1" textSize="display2">
                                Benvenuto su Squiseat Store!
                            </Text>
                            <Text m={{ t: "3rem" }} textSize="title">
                                Possiedi un locale?
                            </Text>
                        </Container>
                    </Div>
                    <Div
                        textAlign="center"
                        pos="fixed"
                        transition
                        left={state >= 1 ? "0" : "100vw"}
                        bg="white"
                        p={{ t: "env(safe-area-inset-top)" }}
                        w="100vw"
                        h="100vh"
                    >
                        <Container>
                            <Image m={{ t: "3rem" }} w="25vh" src="icon-locali.png" />
                            <Text m={{ t: "3rem" }} tag="h1" textSize="display2">
                                Vendi
                            </Text>
                            <Text m={{ t: "1rem" }} textSize="subheader">
                                i tuoi prodotti in eccedenza senza sprecare!
                            </Text>
                        </Container>
                    </Div>
                    <Div
                        textAlign="center"
                        pos="fixed"
                        transition
                        left={state >= 2 ? "0" : "100vw"}
                        bg="white"
                        p={{ t: "env(safe-area-inset-top)" }}
                        w="100vw"
                        h="100vh"
                    >
                        <Container>
                            <Image m={{ t: "3rem" }} w="25vh" src="icon-locali.png" />
                            <Text m={{ t: "3rem" }} tag="h1" textSize="display2">
                                Scegli
                            </Text>
                            <Text m={{ t: "1rem" }} textSize="subheader">
                                quali prodotti vendere e in che quantità, con un tap.
                            </Text>
                        </Container>
                    </Div>
                    <Div
                        textAlign="center"
                        pos="fixed"
                        transition
                        left={state >= 3 ? "0" : "100vw"}
                        bg="white"
                        p={{ t: "env(safe-area-inset-top)" }}
                        w="100vw"
                        h="100vh"
                    >
                        <Container>
                            <Image m={{ t: "3rem" }} w="25vh" src="icon-locali.png" />
                            <Text m={{ t: "3rem" }} tag="h1" textSize="display2">
                                Fatto!
                            </Text>
                            <Text m={{ t: "1rem" }} textSize="subheader">
                                Guadagna anche dagli invenduti e organizza meglio la produzione
                            </Text>
                        </Container>
                    </Div>
                    <Div
                        textAlign="center"
                        pos="fixed"
                        transition
                        left={state === 4 ? "0" : "100vw"}
                        bg="white"
                        p={{ t: "env(safe-area-inset-top)" }}
                        w="100vw"
                        h="100vh"
                    >
                        <Container>
                            <Image m={{ t: "3rem" }} w="25vh" src="icon-locali.png" />
                            <Text m={{ t: "3rem" }} tag="h1" textSize="display2">
                                Bene,
                            </Text>
                            <Text m={{ t: "1rem" }} textSize="subheader">
                                il team Squiseat ti conosce già?
                            </Text>
                        </Container>
                    </Div>
                    <Row
                        bg="white"
                        pos="fixed"
                        m="auto"
                        w="100vw"
                        p={{ y: "1rem" }}
                        bottom="env(safe-area-inset-bottom)"
                    >
                        <Col>
                            <Button
                                m="auto"
                                rounded="xl"
                                bg="white"
                                h="3.5rem"
                                p={{ x: "1.5rem" }}
                                textSize="title"
                                textColor={state < 4 ? "light" : "gunmetal"}
                                onClick={
                                    state > 0 && state < 4
                                        ? () => setState(4)
                                        : state === 0
                                        ? async () => {
                                              FirebaseService.analytics.logEvent("onboarding_is_supplier_no");
                                              await Browser.open({
                                                  url:
                                                      "https://www.squiseat.it/come-funziona/?utm_source=app&utm_medium=merchant&utm_campaign=on_boarding_customer",
                                                  toolbarColor: theme.colors.primary,
                                                  presentationStyle: "popover",
                                              });
                                          }
                                        : async () => {
                                              FirebaseService.analytics.logEvent("onboarding_is_supplier_unknown");
                                              await Browser.open({
                                                  url:
                                                      "https://www.squiseat.it/business/?utm_source=app&utm_medium=merchant&utm_campaign=on_boarding_merchant",
                                                  toolbarColor: theme.colors.primary,
                                                  presentationStyle: "popover",
                                              });
                                          }
                                }
                            >
                                {state > 0 && state < 4 ? "Salta" : state === 0 ? "No" : "No, mi presento"}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                m="auto"
                                rounded="20px"
                                bg="gunmetal"
                                shadow="3"
                                h="3.5rem"
                                p={{ x: "1.5rem" }}
                                textSize="title"
                                onClick={
                                    state === 4
                                        ? handleOnboard
                                        : () => {
                                              setState(state + 1);
                                          }
                                }
                            >
                                {state > 0 && state < 4 ? "Avanti" : "Sì!"}
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Div>
    );
}

export default Onboarding;
