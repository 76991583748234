import { Div, Input, Text, Button, Row, Col, Icon, Checkbox, Label } from "atomize";
import PersonIcon from "@material-ui/icons/Person";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useContext, useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import FirebaseService from "../../services/firebase.service";
import { toast } from "react-toastify";
import theme from "../../appearance/theme";
import { authContext } from "../../providers/auth.provider";
import BoldText from "../boldText/BoldText";
import { modalContext } from "../../providers/modal.provider";
import { getRemoteValue } from "../../services/func.utils";
import { Browser } from "@capacitor/browser";
import { Storage } from "@capacitor/storage";

function Authentication(props) {
    const [formType, setFormType] = useState(0);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPasswordLogin, setShowPasswordLogin] = useState(false);
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

    const [userName, setUserName] = useState("");
    const [loginPsw, setLoginPsw] = useState("");
    const [signUpPsw2, setSignUpPsw2] = useState("");

    const [modalInput, setModalInput] = useState("");

    const { setLog } = useContext(authContext);
    const Modal = useContext(modalContext);

    const getRegistrationState = async () => {
        const ret = await Storage.get({ key: "registrationState" });
        const registrationState = ret.value;
        setFormType(!registrationState ? 1 : 0);
    };

    useEffect(() => {
        getRegistrationState();
    }, []);

    const handleLoginChange = (type, e) => {
        type === "user" ? setUserName(e.target.value) : setLoginPsw(e.target.value);
    };
    const handleModalInputChange = (type, e) => {
        setModalInput(e.target.value);
    };
    const handleSignupChange = (type, e) => {
        type === "email"
            ? setUserName(e.target.value)
            : type === "password"
            ? setLoginPsw(e.target.value)
            : setSignUpPsw2(e.target.value);
    };

    const handleLogin = (registrationAttempt) => {
        if (checkInputsValidation("login")) {
            AuthService.login(userName, loginPsw)
                .then((response) => {
                    setLog(true);
                    FirebaseService.analytics.logEvent("login");
                    Storage.set({
                        key: "registrationState",
                        value: "done",
                    });
                    registrationAttempt && toast.success("Hey, eri già registrato!");
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        console.warn("[API] Wrong email or password ", error);
                        if (registrationAttempt) {
                            toast.error("Indirizzo mail già registrato, ma password errata, RIPROVA!", {
                                position: "top-center",
                            });
                            setFormType(0);
                        } else toast.error("Indirizzo mail o password errati, RIPROVA!", { position: "top-center" });
                    } else {
                        console.error("[API] Login ", error);
                        toast.error("Sembra esserci stato un problema con il login, RIPROVA!", {
                            position: "top-center",
                        });
                    }
                });
        }
    };

    const handleRegister = () => {
        if (checkInputsValidation("register")) {
            AuthService.register(userName, loginPsw)
                .then((response) => {
                    setLog(true);
                    FirebaseService.analytics.logEvent("register");
                    Storage.set({
                        key: "registrationState",
                        value: "done",
                    });
                })
                .catch((error) => {
                    if (error.response?.status === 400) {
                        error.response?.data?.message === "Impossible to register! This account already exists" &&
                            handleLogin(true);
                        console.warn("[API] Wrong email/password or already registered user ", error);
                        error.response?.data?.message === "Non esiste un account associato a questa email" &&
                            toast.error(
                                "Nome utente (mail) o password errati, oppure l'utente è già registrato, RIPROVA!",
                                { position: "top-center" }
                            );
                    } else {
                        console.error("[API] Registration", error);
                        toast.error("Registrazione non a buon fine, RIPROVA!", { position: "top-center" });
                    }
                });
        }
    };

    function checkInputsValidation(type) {
        const mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (type === "login" || type === "recovery") {
            if (userName === "" || loginPsw === "") {
                toast.warning("Completa tutti i campi!");
            } else if (!mailPattern.test(userName.trim())) {
                toast.warning("La mail non è corretta!");
            } else return true;
        } else if (type === "register") {
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,}$/;
            if (userName === "" || loginPsw === "" || signUpPsw2 === "") {
                toast.warning("Completa tutti i campi!");
            } else if (!mailPattern.test(userName.trim())) {
                toast.warning("La mail non è corretta!");
            } else if (!passwordPattern.test(loginPsw)) {
                toast.warning(
                    "La password deve contenere almeno 8 caratteri: 1 lettera maiuscola, 1 lettera minuscola e 1 numero. I caratteri speciali ammessi sono: @$!#%*?&"
                );
            } else if (loginPsw !== signUpPsw2) {
                toast.warning("Le password non coincidono");
            } else if (!acceptTermsAndConditions) {
                toast.warning("Devi accettare i Termini e condizioni di utilizzo!");
            } else return true;
        }
        return false;
    }

    return (
        <>
            <Div p="1rem">
                <Div rounded="20px" opacity={props.searching ? "0" : "1"} transition bg="white" shadow="3" p="0.2rem">
                    <Row>
                        <Col>
                            <Button
                                bg={!formType ? "gunmetal" : "white"}
                                rounded="20px"
                                w="100%"
                                onClick={() => setFormType(false)}
                            >
                                <Text
                                    textSize="caption"
                                    textColor={!formType ? "white" : "dark"}
                                    textWeight={!formType && "bold"}
                                >
                                    LOGIN
                                </Text>
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                bg={formType ? "gunmetal" : "white"}
                                rounded="20px"
                                w="100%"
                                onClick={() => setFormType(true)}
                            >
                                <Text
                                    textSize="caption"
                                    textColor={formType ? "white" : "dark"}
                                    textWeight={formType && "bold"}
                                >
                                    REGISTRAZIONE
                                </Text>
                            </Button>
                        </Col>
                    </Row>
                </Div>
                {!formType ? (
                    <Div m={{ x: "1.5rem" }}>
                        <Text d="flex" justify="center" p={{ y: "1rem" }} textSize="display2" textColor="dark">
                            Login
                        </Text>
                        <Div p={{ t: "1rem" }}>
                            <Text textSize="subheader">
                                Indirizzo <b>mail</b>:
                            </Text>
                            <Input
                                type="text"
                                name="userName"
                                value={userName}
                                autoComplete="off"
                                onChange={(e) => handleLoginChange("user", e)}
                                placeholder="Inserisci la mail"
                                p={{ l: "3rem" }}
                                bg="gray_light"
                                rounded="20px"
                                h="3.5rem"
                                border="3px solid"
                                borderColor="gray_light"
                                focusBorderColor="gunmetal"
                                prefix={
                                    <PersonIcon
                                        style={{
                                            color: "#A7AAB0",
                                            position: "absolute",
                                            top: "0.85rem",
                                            left: "0.75rem",
                                        }}
                                        size="20px"
                                        cursor="pointer"
                                    />
                                }
                            />
                        </Div>
                        <Div m={{ x: "auto" }} p={{ t: "0.5rem" }}>
                            <Text textSize="subheader">
                                <b>Password</b>:
                            </Text>
                            <Input
                                type={showPasswordLogin ? "text" : "password"}
                                name="password"
                                value={loginPsw}
                                autoComplete="off"
                                onChange={(e) => handleLoginChange("password", e)}
                                placeholder="Inserisci la password"
                                p={{ l: "3rem" }}
                                bg="gray_light"
                                rounded="20px"
                                h="3.5rem"
                                border="3px solid"
                                borderColor="gray_light"
                                focusBorderColor="gunmetal"
                                prefix={
                                    <LockOutlinedIcon
                                        style={{
                                            color: "#A7AAB0",
                                            position: "absolute",
                                            top: "0.85rem",
                                            left: "0.75rem",
                                        }}
                                        size="20px"
                                        cursor="pointer"
                                    />
                                }
                                suffix={
                                    <Button
                                        pos="absolute"
                                        onClick={() => setShowPasswordLogin(!showPasswordLogin)}
                                        bg="transparent"
                                        w="3rem"
                                        top="0.5rem"
                                        right="0"
                                        rounded={{ r: "md" }}
                                    >
                                        <Icon
                                            name={showPasswordLogin ? "EyeSolid" : "Eye"}
                                            color={showPasswordLogin ? theme.colors.gunmetal : theme.colors.text_light}
                                            size="16px"
                                        />
                                    </Button>
                                }
                            />
                        </Div>
                        <Div d="flex" justify="center" p={{ y: "1rem" }}>
                            <Button
                                type="submit"
                                bg="primary"
                                textColor="white"
                                rounded="20px"
                                border="0"
                                p={{ x: "3rem" }}
                                shadow="3"
                                hoverShadow="4"
                                onClick={() => handleLogin()}
                            >
                                ENTRA
                            </Button>
                        </Div>
                        <Div d="flex" justify="center" p={{ t: "1rem" }}>
                            <Button
                                bg="transparent"
                                textColor="dark"
                                textSize="subheader"
                                onClick={async () =>
                                    await Browser.open({
                                        url: "https://squiseat.com/SquisEatWeb/recoverypassword",
                                        toolbarColor: theme.colors.gunmetal,
                                        presentationStyle: "popover",
                                    })
                                }
                            >
                                Ho dimenticato la password!
                            </Button>
                        </Div>
                    </Div>
                ) : (
                    <Div m={{ x: "1.5rem" }}>
                        <Text d="flex" justify="center" p={{ y: "1rem" }} textSize="display2" textColor="dark">
                            Registrazione
                        </Text>
                        <Div m={{ x: "auto" }} p={{ y: "1rem" }}>
                            <Text textSize="subheader">
                                Indirizzo <b>mail</b>:
                            </Text>
                            <Input
                                type="text"
                                name="email"
                                value={userName}
                                autoComplete="off"
                                onChange={(e) => handleSignupChange("email", e)}
                                placeholder="Inserisci la mail"
                                p={{ l: "3rem" }}
                                bg="gray_light"
                                rounded="20px"
                                h="3.5rem"
                                border="3px solid"
                                borderColor="gray_light"
                                focusBorderColor="gunmetal"
                                prefix={
                                    <PersonIcon
                                        style={{
                                            color: "#A7AAB0",
                                            position: "absolute",
                                            top: "0.85rem",
                                            left: "0.75rem",
                                        }}
                                        size="20px"
                                        cursor="pointer"
                                    />
                                }
                            />
                        </Div>
                        <Div m={{ x: "auto" }} p={{ y: "0.5rem" }}>
                            <Text textSize="subheader">
                                <b>Password</b>:
                            </Text>
                            <Input
                                type={showPassword1 ? "text" : "password"}
                                name="password"
                                value={loginPsw}
                                autoComplete="off"
                                onChange={(e) => handleSignupChange("password", e)}
                                placeholder="Inserisci la password"
                                p={{ l: "3rem" }}
                                bg="gray_light"
                                rounded="20px"
                                h="3.5rem"
                                border="3px solid"
                                borderColor="gray_light"
                                focusBorderColor="gunmetal"
                                prefix={
                                    <LockOutlinedIcon
                                        style={{
                                            color: "#A7AAB0",
                                            position: "absolute",
                                            top: "0.85rem",
                                            left: "0.75rem",
                                        }}
                                        size="20px"
                                        cursor="pointer"
                                    />
                                }
                                suffix={
                                    <Button
                                        pos="absolute"
                                        onClick={() => setShowPassword1(!showPassword1)}
                                        bg="transparent"
                                        w="3rem"
                                        top="0.5rem"
                                        right="0"
                                        rounded={{ r: "md" }}
                                    >
                                        <Icon
                                            name={showPassword1 ? "EyeSolid" : "Eye"}
                                            color={showPassword1 ? theme.colors.gunmetal : theme.colors.text_light}
                                            size="16px"
                                        />
                                    </Button>
                                }
                            />
                        </Div>
                        <Div m={{ x: "auto" }} p={{ y: "0.5rem" }}>
                            <Text textSize="subheader">
                                Ripeti la <b>Password</b>:
                            </Text>
                            <Input
                                type={showPassword2 ? "text" : "password"}
                                name="password2"
                                value={signUpPsw2}
                                autoComplete="off"
                                onChange={(e) => handleSignupChange("password2", e)}
                                placeholder="Inserisci la password di prima"
                                p={{ l: "3rem" }}
                                bg="gray_light"
                                rounded="20px"
                                h="3.5rem"
                                border="3px solid"
                                borderColor="gray_light"
                                focusBorderColor="gunmetal"
                                prefix={
                                    <LockOutlinedIcon
                                        style={{
                                            color: "#A7AAB0",
                                            position: "absolute",
                                            top: "0.85rem",
                                            left: "0.75rem",
                                        }}
                                        size="20px"
                                        cursor="pointer"
                                    />
                                }
                                suffix={
                                    <Button
                                        pos="absolute"
                                        onClick={() => setShowPassword2(!showPassword2)}
                                        bg="transparent"
                                        w="3rem"
                                        top="0.5rem"
                                        right="0"
                                        rounded={{ r: "md" }}
                                    >
                                        <Icon
                                            name={showPassword2 ? "EyeSolid" : "Eye"}
                                            color={showPassword2 ? theme.colors.gunmetal : theme.colors.text_light}
                                            size="16px"
                                        />
                                    </Button>
                                }
                            />
                        </Div>
                        <Label m={{ t: "1rem" }} align="center" textSize="caption">
                            <Checkbox
                                onChange={(e) => setAcceptTermsAndConditions(e.target.checked)}
                                checked={acceptTermsAndConditions}
                                inactiveColor={theme.colors.gunmetal_lighter}
                                activeColor={theme.colors.gunmetal}
                                size="24px"
                            />
                            <Text tag="span">
                                Accetto i{" "}
                                <BoldText
                                    textColor={theme.colors.gunmetal}
                                    textDecor="underline"
                                    action={(e) => Modal.open("termsAndConditions")}
                                >
                                    Termini e condizioni di utilizzo
                                </BoldText>
                            </Text>
                        </Label>
                        <Div d="flex" justify="center" p={{ y: "1rem" }}>
                            <Button
                                type="submit"
                                name="signup"
                                bg="primary"
                                textColor="white"
                                rounded="20px"
                                border="0"
                                p={{ x: "3rem" }}
                                shadow="3"
                                hoverShadow="4"
                                onClick={() => handleRegister()}
                            >
                                REGISTRATI
                            </Button>
                        </Div>
                        {JSON.parse(getRemoteValue("onboarding_enabled")) && (
                            <>
                                <hr style={{ border: "1px solid #E1E4E8" }} />
                                <Text
                                    textSize="title"
                                    textWeight="bold"
                                    textAlign="center"
                                    p={{ t: "1rem", b: "0.5rem" }}
                                >
                                    Non riesci a registrarti?
                                </Text>
                                <Text textAlign="center">
                                    <b>Possiedi un locale</b>? Presentati al team.
                                </Text>
                                <Button
                                    m={{ t: " 0.5rem", b: "1.5rem", x: "auto" }}
                                    bg="gunmetal"
                                    onClick={async () =>
                                        await Browser.open({
                                            url:
                                                "https://squiseat.it/business/?utm_source=app&utm_medium=merchant&utm_campaign=on_boarding_merchant",
                                            toolbarColor: theme.colors.primary,
                                            presentationStyle: "popover",
                                        })
                                    }
                                >
                                    Presentati al team Squiseat!
                                </Button>
                                <hr style={{ border: "1px solid #E1E4E8" }} />
                                <Text
                                    textSize="title"
                                    textWeight="bold"
                                    textAlign="center"
                                    p={{ t: "1rem", b: "0.5rem" }}
                                >
                                    Vuoi ordinare da Squiseat?
                                </Text>
                                <Text textAlign="center">Questa è l'app per i locali.</Text>
                                <Button
                                    m={{ t: " 0.5rem", x: "auto" }}
                                    bg="gunmetal"
                                    onClick={async () =>
                                        await Browser.open({
                                            url:
                                                "https://squiseat.it/come-funziona/?utm_source=app&utm_medium=merchant&utm_campaign=on_boarding_customer",
                                            toolbarColor: theme.colors.primary,
                                            presentationStyle: "popover",
                                        })
                                    }
                                >
                                    Ecco come puoi fare!
                                </Button>
                            </>
                        )}
                    </Div>
                )}
            </Div>
        </>
    );
}

export default Authentication;
