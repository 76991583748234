import React, { useContext, useEffect, useState } from "react";
import { authContext } from "./auth.provider";
import apiService from "../services/api.service";
import { toast } from "react-toastify";
import config from "../settings/config";
import axios from "axios";
import moment from "moment";
import { cloneDeep } from "lodash";
import { modalContext } from "./modal.provider";
import {
    addProductImage,
    getProdsInternalID,
    insertProdInternalID,
    updateProdInternalID,
} from "../services/firebase.service";
import { App } from "@capacitor/app";
let cancelToken = null;

export const productsContext = React.createContext();

const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState(null);
    const [searchProducts, setSearchProducts] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [categoryList, setCategoryList] = useState(null);
    const [productsUnconfirmed, setProductsUnconfirmed] = useState(null);
    const [productsOnline, setProductsOnline] = useState(null);
    const [prodsInternalID, setProdsInternalID] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [productInfoSelected, setProductInfoSelected] = useState(null);

    const [searching, setSearch] = useState(false);
    const [productsType, setProductsType] = useState(false);
    const [productsDisplayed, setProductsDisplayed] = useState(null);
    const [expirationList, setExpirationList] = useState(null);

    const { logged, setLog, supplierData } = useContext(authContext);
    const Modal = useContext(modalContext);

    const productsEntries = {
        products: products,
        online: productsOnline,
        unconfirmed: productsUnconfirmed,
        categories: categoryList,
        searchProducts: searchProducts,
        internalID: prodsInternalID ?? {},
    };

    const mergedProdProps = Object.assign({}, productsEntries, {
        expiration: expirationList,
        productsDisplayed: productsDisplayed,
    });

    const handleSearchButtonClick = () => setSearch(!searching);
    const handleProductsTypeChange = (type) => setProductsType(type);

    const addUnconfirmed = async (productInfo, quantity, soldAt, remainingUnconfirmed) => {
        return await apiService.api
            .put("/unconfirmedProducts", {
                productID: productInfo.productID,
                quantity: quantity,
                price: productInfo.fullPrice,
                soldAt: soldAt,
            })
            .then((response) => {
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : !supplierData?.firestore?.cannotUseOldProdQuantityModal &&
                      !supplierData?.firestore?.usesNewProdQuantityModal &&
                      insertUnconfirmed(response.data[0], remainingUnconfirmed);
                return response.status;
            })
            .catch((error) => {
                console.error("[API] Add unconfirmed ", error);
                toast.error("Ops, non sono riuscito ad aggiungere il prodotto ai prodotti da confermare, Riprova!", {
                    hideProgressBar: false,
                    onClose: () => getUnconfirmedProducts(),
                });
            });
    };

    const insertUnconfirmed = (newProductInfo, remainingUnconfirmed) => {
        let tmpUnconfirmed = remainingUnconfirmed ? { ...remainingUnconfirmed } : { ...productsUnconfirmed };
        newProductInfo["type"] in tmpUnconfirmed
            ? tmpUnconfirmed[newProductInfo["type"]].push(newProductInfo)
            : (tmpUnconfirmed[newProductInfo["type"]] = [newProductInfo]);
        setProductsUnconfirmed(tmpUnconfirmed);
    };

    async function editUnconfirmed(productInfo, newProductInfo) {
        updateUnconfirmed(productInfo, newProductInfo);
        if (Object.keys(newProductInfo)[0] === "quantity")
            await apiService.api
                .post("/unconfirmedProducts", {
                    productID: productInfo.productID,
                    quantity: newProductInfo.quantity,
                    priceID: productInfo.unconfirmed.priceID,
                })
                .then((response) => {
                    apiService.checkRedirectLogin(response.request.responseURL)
                        ? setLog(false)
                        : !(productInfo.unconfirmed.quantity === response.data[0]["quantity"]) &&
                          updateUnconfirmed(response.data[0], newProductInfo);
                })
                .catch((error) => {
                    console.error("[API] Edit unconfirmed ", error);
                    toast.error(
                        "Ops, non sono riuscito a modificare le porzioni del prodotto da confermare, Riprova!",
                        {
                            hideProgressBar: false,
                            onClose: () => getUnconfirmedProducts(),
                        }
                    );
                });
    }

    const updateUnconfirmed = (productInfo, newProductInfo) => {
        let tmpUnconfirmed = { ...productsUnconfirmed };
        let updatedPropery = Object.keys(newProductInfo)[0];
        Object.keys(productsUnconfirmed).forEach((category) =>
            productsUnconfirmed[category].forEach((value, index) => {
                productInfo.productID === value.productID &&
                    (tmpUnconfirmed[category][index][updatedPropery] = newProductInfo[updatedPropery]);
            })
        );
        setProductsUnconfirmed(tmpUnconfirmed);
    };

    const deleteUnconfirmed = async (productInfo, addAfterRemove) => {
        /*const dataResponse = await apiService.delete(config.ApiUrl+"/unconfirmedProducts", {
                data: {productID:productInfo.productID,priceID:productInfo.priceID},
                headers: {'Content-Type': 'application/json'}
            })*/
        //DA FIXARE QUANDO RISOLVONO BACO DELETE AXIOS
        return await apiService.api
            .request({
                url: config.ApiUrl + "/unconfirmedProducts",
                method: "delete",
                data: {
                    productID: productInfo.productID,
                    priceID: productInfo.priceID,
                },
            })
            .then((response) => {
                apiService.checkRedirectLogin(response.request.responseURL) && setLog(false);
                return removeUnconfirmed(productInfo, addAfterRemove);
            })
            .catch((error) => {
                console.error("[API] Delete unconfirmed ", error);
                toast.error("Ops, il prodotto non è stato rimosso, Riprova!", {
                    hideProgressBar: false,
                    onClose: () => getUnconfirmedProducts(),
                });
            });
    };

    const removeUnconfirmed = (productInfo, addAfterRemove) => {
        let tmpUnconfirmed = { ...productsUnconfirmed };
        let filteredArray = null;
        filteredArray = tmpUnconfirmed[productInfo["type"]].filter((item) => {
            return item.productID !== productInfo.productID;
        });
        tmpUnconfirmed[productInfo["type"]] = filteredArray;
        tmpUnconfirmed[productInfo["type"]].length === 0 && delete tmpUnconfirmed[productInfo["type"]];
        if (addAfterRemove) return tmpUnconfirmed;
        else setProductsUnconfirmed(tmpUnconfirmed);
    };

    const editAvailability = async (productInfo, editedData, type) => {
        productInfo[type].quantity = productInfo[type].quantity - productInfo[type].available + editedData.quantity;
        productInfo[type].available = editedData.quantity > 0 ? editedData.quantity : 0;
        updateAvailability(productInfo, type);
        //removeAvailability(productInfo)
        return await apiService.api
            .post("/availability/" + productInfo[type].availabilityID, {
                ...("quantity" in editedData && { quantity: editedData.quantity }),
                ...("soldAt" in editedData && { soldAt: editedData.soldAt }),
            })
            .then((response) => {
                if (apiService.checkRedirectLogin(response.request.responseURL)) {
                    setLog(false);
                } else {
                    getReport();
                    if (response.data?.isExpired === 0) {
                        if (!(productInfo[type].available === response.data.quantity)) {
                            productInfo[type].quantity = response.data.quantity;
                            productInfo[type].available = response.data.available;
                            updateAvailability(productInfo, type);
                        }
                        if (productInfo[type].availabilityID !== response.data.availabilityID) {
                            removeAvailability(productInfo[type]);
                            let newProduct = response.data[Object.keys(response.data)[0]];
                            setProductsOnline({ ...newProduct });
                        }
                    } else if (response.data?.isExpired === 1) {
                        toast.success("Prodotto non più online!");
                        removeAvailability(response.data);
                    }
                    return response.data;
                }
            })
            .catch((error) => {
                if (error.response?.status === 400) {
                    console.warn(`[API] Product Expired (availabilityID: ${productInfo.availabilityID})`, error);
                    toast.warning("Ops, il prodotto non è online! Caricalo di nuovo");
                    removeAvailability(productInfo);
                } else {
                    console.error("[API] Edit availability ", error);
                    toast.error("Ops, non sono riuscito a modificare le porzioni del prodotto in vendita, Riprova!", {
                        hideProgressBar: false,
                        onClose: () => getOnlineProducts(),
                    });
                }
            });
    };

    const publishAvailability = async (date, time, type) => {
        setProductsUnconfirmed({});
        return await apiService.api
            .post("/publishAvailability", {
                expiringTime: time,
                expiringDate: date,
            })
            .then((response) => {
                getReport();
                let newProducts = response.data[Object.keys(response.data)[0]];
                delete newProducts["alreadySold"];
                delete newProducts["deliveryHourInfo"];
                delete newProducts["notSold"];
                delete newProducts["total"];
                setProductsOnline({ ...newProducts });
                return newProducts;
            })
            .catch((error) => {
                console.error("[API] Publish availability ", error);
                toast.error("Ops, non sono riuscito a pubblicare i prodotti, Riprova!", {
                    hideProgressBar: false,
                    onClose: () => {
                        getOnlineProducts();
                        getUnconfirmedProducts();
                    },
                });
            });
    };

    const expireAvailability = async (availabilityID) => {
        return await apiService.api
            .post(`/availability/${availabilityID}`, {
                expiringDate: moment().format("YYYY-MM-DD"),
                expiringTime: moment().format("HH:mm"),
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error("[API] expireAvailability ", error);
            });
    };

    const updateAvailability = (newProductInfo, type) => {
        let tmpOnline = { ...productsOnline };
        Object.keys(productsOnline).forEach((category) => {
            productsOnline[category].forEach((value, index) => {
                if (value.availabilityID === newProductInfo[type].availabilityID) {
                    if (newProductInfo[type].quantity > 0) {
                        tmpOnline[category][index]["quantity"] = newProductInfo[type].quantity;
                        tmpOnline[category][index]["available"] = newProductInfo[type].available;
                    } else delete tmpOnline[category][index];
                }
            });
        });
        setProductsOnline(tmpOnline);
    };

    const insertAvailability = (newProductInfo) => {
        let tmpOnline = { ...productsOnline };
        newProductInfo["type"] in tmpOnline
            ? tmpOnline[newProductInfo["type"]].push(newProductInfo)
            : (tmpOnline[newProductInfo["type"]] = [newProductInfo]);
        setProductsOnline(tmpOnline);
    };

    const removeAvailability = (productInfo) => {
        let tmpOnline = productsOnline;
        const filteredArray = tmpOnline[productInfo["type"]].filter((item) => item.productID !== productInfo.productID);
        tmpOnline[productInfo["type"]] = filteredArray;
        tmpOnline[productInfo["type"]].length === 0 && delete tmpOnline[productInfo["type"]];
        setProductsOnline({ ...tmpOnline });
    };

    const insertProduct = async (
        prodName,
        prodPrice,
        prodCategory,
        prodContent,
        photo,
        switchState,
        prodInternalID
    ) => {
        let propsProd = [];
        prodContent === 1 && propsProd.push("ContainsPork");
        prodContent === 2 && propsProd.push("IsVegetarian");
        prodContent === 3 && propsProd.push("IsVegan");
        await apiService.api
            .post("/products", {
                productName: prodName,
                price: prodPrice,
                type: prodCategory.id,
                props: propsProd,
            })
            .then(async (response) => {
                photo && (await addProductImage(photo, response?.data?.productID));
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : addProduct({ ...response.data, ...(prodInternalID && { productInternalID: prodInternalID }) });
                toast.success("Prodotto aggiunto!", {
                    position: "top-center",
                });
                prodInternalID && insertProdInternalID(response.data, prodInternalID, getInternlIDs);
                if (switchState) {
                    setProductInfoSelected(response.data);
                    Modal.open("prodQuantity");
                } else Modal.close();
                //document.getElementById("cat-" + prodCategory.id).click()
            })
            .catch((error) => {
                console.error("[API] Insert product ", error);
                toast.error("Ops, non sono riuscito ad aggiungere il prodotto, riprova!", {
                    hideProgressBar: false,
                    onClose: () => getProducts(),
                });
            });
    };

    const editProduct = async (newProductData, productID, switchState, prodInternalID) => {
        let oldProductInfo = productInfoSelected;
        if (switchState !== null || !prodInternalID)
            await apiService.api
                .patch(`/products/${productID}`, newProductData)
                .then(async (response) => {
                    apiService.checkRedirectLogin(response.request.responseURL) && setLog(false);
                    switchState === null || switchState === undefined
                        ? toast.success("Prodotto aggiornato!", { position: "top-center" })
                        : toast.success("Prodotto aggiunto!", { position: "top-center" });
                    let newProdDataWithInternalID = {
                        ...response.data,
                        ...(prodInternalID && { productInternalID: prodInternalID }),
                    };
                    if (productInfoSelected.unconfirmed) {
                        newProdDataWithInternalID.unconfirmed = productInfoSelected.unconfirmed;
                        if (Object.keys(newProductData)[0] === "fullPrice") {
                            let remainingUnconfirmed = await deleteUnconfirmed(oldProductInfo, true);
                            addUnconfirmed(
                                newProdDataWithInternalID,
                                oldProductInfo.unconfirmed.quantity,
                                0.5,
                                remainingUnconfirmed
                            );
                        } else editUnconfirmed(productInfoSelected, newProductData);
                    }
                    updateProduct(newProdDataWithInternalID, oldProductInfo.type);

                    // modifica di un prodotto che era stato eliminato
                    if (switchState) Modal.open("prodQuantity");
                    else if (switchState === false) Modal.close();
                })
                .catch((error) => {
                    console.error("[API] Edit product ", error);
                    toast.error("Ops, non sono riuscito ad aggiornare il prodotto, riprova!", {
                        hideProgressBar: false,
                        onClose: () => getProducts(),
                    });
                });
        else {
            productsEntries.internalID[newProductData.productID]
                ? updateProdInternalID(newProductData, prodInternalID, getInternlIDs)
                : insertProdInternalID(newProductData, prodInternalID, getInternlIDs);
            let newProdDataWithInternalID = {
                ...newProductData,
                ...(prodInternalID && { productInternalID: prodInternalID }),
            };
            if (productInfoSelected.unconfirmed) {
                newProdDataWithInternalID.unconfirmed = productInfoSelected.unconfirmed;
                updateProduct(newProdDataWithInternalID, oldProductInfo.type);
                if (Object.keys(newProductData)[0] === "fullPrice") {
                    let remainingUnconfirmed = await deleteUnconfirmed(oldProductInfo, true);
                    addUnconfirmed(
                        newProdDataWithInternalID,
                        oldProductInfo.unconfirmed.quantity,
                        0.5,
                        remainingUnconfirmed
                    );
                } else editUnconfirmed(productInfoSelected, newProductData);
            } else updateProduct(newProdDataWithInternalID, oldProductInfo.type);

            // modifica di un prodotto che era stato eliminato
            if (switchState) Modal.open("prodQuantity");
            else if (switchState === false) Modal.close();
        }
    };

    const getSearchProducts = async (searchValue) => {
        const searchListResult = {};

        Object.keys(productsEntries.products).forEach((type) => {
            productsEntries.products[type].forEach((product) => {
                if (
                    product.productName.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    productsEntries?.internalID[product.productID]?.toLowerCase().includes(searchValue?.toLowerCase())
                ) {
                    if (product.type in searchListResult) {
                        searchListResult[product.type].push(product);
                    } else {
                        searchListResult[product.type] = [product];
                    }
                }
            });
        });
        setSearchProducts(searchListResult);
    };

    const addCategory = (newType) => {
        let tmpCategory = categoryList;
        !tmpCategory.includes(newType) && tmpCategory.push(newType) && setCategoryList(tmpCategory);
    };

    const addProduct = (newProductInfo) => {
        let tmpProducts = products;
        newProductInfo["type"] in tmpProducts
            ? tmpProducts[newProductInfo["type"]].push(newProductInfo)
            : (tmpProducts[newProductInfo["type"]] = [newProductInfo]) &&
              getCategories(); /*(addCategory(newProductInfo["type"]))*/
        setProducts({ ...tmpProducts });
        console.log(tmpProducts);
    };

    const updateProduct = (updatedProductInfo, oldProductType) => {
        let tmpProducts = products;
        let tmpUpdatedProductInfo = { ...updatedProductInfo };
        tmpUpdatedProductInfo.unconfirmed && delete tmpUpdatedProductInfo.unconfirmed;

        if (oldProductType === tmpUpdatedProductInfo.type) {
            if (tmpUpdatedProductInfo?.type in tmpProducts) {
                let oldProductIndex = tmpProducts[tmpUpdatedProductInfo?.type].findIndex(
                    (product) => product.productID === tmpUpdatedProductInfo.productID
                );
                tmpProducts[tmpUpdatedProductInfo?.type][oldProductIndex] = tmpUpdatedProductInfo;
            } else {
                tmpProducts[tmpUpdatedProductInfo?.type] = [tmpUpdatedProductInfo];
                getCategories();
            }
        } else {
            let oldProductIndex = tmpProducts[oldProductType].findIndex(
                (product) => product.productID === tmpUpdatedProductInfo.productID
            );
            tmpProducts[oldProductType].splice(oldProductIndex, 1);
            tmpProducts[oldProductType].length === 0 && delete tmpProducts[oldProductType];
            if (tmpUpdatedProductInfo?.type in tmpProducts)
                tmpProducts[tmpUpdatedProductInfo?.type].push(tmpUpdatedProductInfo);
            else {
                tmpProducts[tmpUpdatedProductInfo?.type] = [tmpUpdatedProductInfo];
                getCategories();
            }
        }
        setProducts({ ...tmpProducts });
        getSearchProducts(searchText);
        setProductInfoSelected(updatedProductInfo);
    };

    const editFunctions = {
        insertProduct: insertProduct,
        editProduct: editProduct,
        addUnconfirmed: addUnconfirmed,
        editUnconfirmed: editUnconfirmed,
        deleteUnconfirmed: deleteUnconfirmed,
        editAvailability: editAvailability,
        publishAvailability: publishAvailability,
        expireAvailability: expireAvailability,
        setSearchProducts: setSearchProducts,
    };

    const getCategories = async () => {
        await apiService.api
            .get("/productTypes/supplier")
            .then((response) => {
                //const list = data.map(x => { return (x.type) });
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setCategoryList(response.data);
            })
            .catch((error) => {
                console.error("[API] Get categories ", error);
                toast.error("Ops, non trovo le categorie dei prodotti, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => getCategories(),
                });
            });
    };

    const getOnlineProducts = async () => {
        await apiService.api
            .get("/availability/")
            .then((response) => {
                delete response.data["alreadySold"];
                delete response.data["deliveryHourInfo"];
                delete response.data["notSold"];
                delete response.data["total"];
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setProductsOnline(response.data);
            })
            .catch((error) => {
                console.error("[API] Get online products ", error);
                toast.error("Ops, non trovo i prodotti attualmente online, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => getOnlineProducts(),
                });
            });
    };

    const getUnconfirmedProducts = async () => {
        await apiService.api
            .get("/unconfirmedProducts")
            .then((response) => {
                delete response.data["total"];
                delete response.data["totalEntries"];
                delete response.data["totalPortions"];
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setProductsUnconfirmed(response.data);
            })
            .catch((error) => {
                console.error("[API] Get unconfirmed products ", error);
                toast.error("Ops, non trovo i prodotti attualmente da confermare, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => getUnconfirmedProducts(),
                });
            });
    };

    const getProducts = async () => {
        await apiService.api
            .get("/products")
            .then((response) => {
                delete response.data["total"];
                delete response.data["totalEntries"];
                delete response.data["totalPortions"];
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setProducts(response.data);
            })
            .catch((error) => {
                console.error("[API] Get products ", error);
                toast.error("Ops, non trovo i prodotti, Aggiorno la pagina!", {
                    hideProgressBar: false,
                    onClose: () => getProducts(),
                });
            });
    };

    const getReport = async () => {
        const date = moment().format("DD-MM-YYYY");
        if (cancelToken) {
            cancelToken.cancel("CANCELED");
        }
        cancelToken = axios.CancelToken.source();
        await apiService.api
            .get("/report/", {
                params: { startDate: date, endDate: date },
                cancelToken: cancelToken.token,
            })
            .then((response) => {
                apiService.checkRedirectLogin(response.request.responseURL)
                    ? setLog(false)
                    : setReportData(response.data);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    setReportData({});
                    toast.error("C'è stato un problema con l' aggiornamento dei guadagni");
                    console.error("[API] Report sold ", error);
                }
            });
    };

    const setHandlerApp = () => {
        App.addListener("appStateChange", (state) => {
            console.log("App state changed. Is active?", state.isActive);
            if (state.isActive) {
                getCategories();
                getProducts();
                getUnconfirmedProducts();
                getOnlineProducts();
                getReport();
            }
        });
    };

    function mergeProducts(products) {
        let tmpListE = [];
        let tmpOnline = { ...productsEntries.online };
        let tmpUncofirmed = { ...productsEntries.unconfirmed };
        let newAllProducts = cloneDeep(products);

        Object.keys(newAllProducts).forEach((category) => {
            //newAllProducts[category] = [ ...allProducts[category].sort((a, b) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))]

            newAllProducts[category].forEach((product, index) => {
                !(tmpOnline === null) &&
                    Object.keys(tmpOnline).forEach((x) => {
                        tmpOnline[x].forEach((y, z) => {
                            let availableProductInfo = {};
                            const d = new Date();
                            const zoneOffset = -d.getTimezoneOffset() / 60;
                            let datum = Date.parse(
                                y.expiringDate + "T" + y.deliveryHourStart + "+0" + zoneOffset + ":00"
                            );

                            product.productID === y.productID &&
                                !y.isFresh &&
                                !tmpListE.includes(datum) &&
                                tmpListE.push(datum);

                            if (newAllProducts[category][index].productID === y.productID) {
                                availableProductInfo["availabilityID"] = y["availabilityID"];
                                availableProductInfo["available"] = y["available"];
                                availableProductInfo["quantity"] = y["quantity"];
                                availableProductInfo["price"] = y.price;
                                availableProductInfo["expiringDate"] = y["expiringDate"];
                                availableProductInfo["expiringTime"] = y["expiringTime"];
                                availableProductInfo["timestamp"] = datum;
                                availableProductInfo["soldAt"] = y["soldAt"];
                                newAllProducts[category][index]["status"] = "online";
                                if (y["isFresh"] === false) {
                                    newAllProducts[category][index]["surplus"] = availableProductInfo;
                                } else {
                                    newAllProducts[category][index]["fresh"] = availableProductInfo;
                                }
                            }
                        });
                    });
                !(tmpUncofirmed === null) &&
                    Object.keys(tmpUncofirmed).forEach((x) => {
                        tmpUncofirmed[x].forEach((y, z) => {
                            if (newAllProducts[category][index]["productID"] === y["productID"]) {
                                newAllProducts[category][index]["unconfirmed"] = {};
                                newAllProducts[category][index]["unconfirmed"]["quantity"] = y["quantity"];
                                newAllProducts[category][index]["unconfirmed"]["soldAt"] = y["soldAt"];
                                newAllProducts[category][index]["unconfirmed"]["priceID"] = y["priceID"];
                                newAllProducts[category][index]["unconfirmed"]["price"] = y["price"];
                                newAllProducts[category][index]["status"] = "unconfirmed";
                            }
                            //(newAllProducts[category][index]["productID"] === y["productID"]) && (newAllProducts[category][index]["unconfirmed"] = tmpUncofirmed[x][z]) && (newAllProducts[category][index]["status"] = "unconfirmed")
                        });
                    });
            });
        });
        tmpListE.sort();
        setExpirationList(tmpListE);
        setProductsDisplayed(newAllProducts);
    }

    function getInternlIDs() {
        getProdsInternalID(supplierData.supplierID).then((result) => {
            setProdsInternalID(result);
        });
    }

    useEffect(() => {
        if (supplierData?.supplierID) {
            getInternlIDs();
        }
    }, [supplierData?.firestore?.needsProdInternalID]);

    useEffect(() => {
        productsEntries.products &&
            productsEntries.online &&
            productsEntries.unconfirmed &&
            (searching && productsEntries.searchProducts
                ? mergeProducts(productsEntries.searchProducts)
                : mergeProducts(productsEntries.products));
    }, [
        productsEntries.products,
        productsEntries.online,
        productsEntries.unconfirmed,
        searching,
        productsEntries.searchProducts,
    ]);

    useEffect(() => {
        if (logged) {
            setHandlerApp();
            getCategories();
            getProducts();
            getUnconfirmedProducts();
            getOnlineProducts();
            getReport();
        }
    }, [logged]);

    return (
        <productsContext.Provider
            value={{
                editFunctions,
                productsEntries,
                productInfoSelected,
                mergedProdProps,
                searching,
                productsType,
                searchProducts,
                searchText,
                setProductInfoSelected,
                handleSearchButtonClick,
                handleProductsTypeChange,
                setSearchProducts,
                getSearchProducts,
                setSearchText,
            }}
        >
            {children}
        </productsContext.Provider>
    );
};

export default ProductsProvider;
