import { Div, Button, Text, Container } from "atomize";
import React, { useEffect, useState, useContext } from "react";
import AuthService from "../../services/auth.service";
import ApiService from "../../services/api.service";
import NotificationService from "../../services/notification.service";
import FirebaseService, { getSupplierReview } from "../../services/firebase.service";
import EarnReportCard from "./EarnReportCard";
import Card from "../atoms/Card";
import { toast } from "react-toastify";
import IosNotchHeader from "../atoms/IosNotchHeader";
import * as Sentry from "@sentry/react";
import SupplierOpenHourCard from "../supplierOpenHourCard/SupplierOpenHourCard";
import UndoToast from "../toast/UndoToast";
import { motion } from "framer-motion";
import { version } from "../../../package.json";
import config from "../../settings/config";
import { authContext } from "../../providers/auth.provider";
import SupplierStatsCard from "../supplierStatsCard/SupplierStatsCard";
import { modalContext } from "../../providers/modal.provider";
import { getRemoteValue } from "../../services/func.utils";
import SupplierReviewCard from "../supplierReviewCard/SupplierReviewCard";
import SettingsCard from "../settingsCard/SettingsCard";
import moment from "moment";
import { PushNotifications } from "@capacitor/push-notifications";
import { Storage } from "@capacitor/storage";

function HomeTab(props) {
    const [tokenNotification, setTokenNotification] = useState(null);

    const { logged, setLog, supplierData, setSupplierData, tab, setTab } = useContext(authContext);
    const Modal = useContext(modalContext);

    let undoToast = false;
    const setUndoState = (val) => {
        undoToast = val;
    };

    const handleToken = () => {
        config.type === "app" &&
            PushNotifications.addListener("registration", (token) => {
                console.log("Push registration success, token: " + token.value);
                setTokenNotification(token.value);
            });
    };

    const setStorageSupplierData = async (data) => {
        await Storage.set({
            key: "supplierData",
            value: JSON.stringify(data),
        });
    };

    const getProfile = () => {
        ApiService.profile()
            .then((response) => {
                if (ApiService.checkRedirectLogin(response.request.responseURL)) {
                    setLog(false);
                } else {
                    NotificationService.initNotification(response.data);
                    !supplierData &&
                        Sentry.setUser({
                            email: response.data.supplierEmail,
                            id: response.data.supplierID,
                            name: response.data.supplierName,
                        });
                    setStorageSupplierData(response.data);
                    getSupplierReview(response.data.supplierID);
                    setSupplierData((prevState) => ({ ...prevState, ...response.data }));
                    setLog(true);
                }
            })
            .catch((error) => {
                console.error("[API] Get profile ", error);
                setLog(false);
            });
    };

    const sendMessage = () => {
        ApiService.sendMessageToCustomerGroup(null)
            .then((response) => {
                if (ApiService.checkRedirectLogin(response.request.responseURL)) setLog(false);
            })
            .catch((error) => {
                console.error("[API] Send message to customer group ", error);
                toast.error("Sembra esserci stato un problema con la richiesta, RIPROVA!");
            });
    };

    useEffect(() => {
        logged && getProfile();
    }, [logged]);

    useEffect(() => {
        handleToken();
    }, []);

    const handleLogout = (e) => {
        e.preventDefault();
        AuthService.logout()
            .then((response) => {
                if (ApiService.checkRedirectLogin(response.request.responseURL)) {
                    tokenNotification &&
                        FirebaseService.removeTokenFromSupplier(supplierData.supplierID.toString(), tokenNotification);
                    setLog(false);
                    setTab("products");
                }
            })
            .catch((error) => {
                console.error("[API] Logout ", error);
                toast.error("Logout non riuscito, RIPROVA!");
            });
    };

    return (
        <>
            {tab === "home" && supplierData?.supplierID && (
                <Div p={{ b: "1rem" }}>
                    <IosNotchHeader />
                    <Card bg="white">
                        <Text textSize="title" textWeight="bold" textAlign="center">
                            {supplierData.supplierName}
                        </Text>
                        <Text textSize="subheader" textAlign="center">
                            {supplierData.supplierAddress}
                        </Text>
                        <Text textSize="subheader" textAlign="center">
                            {supplierData.supplierEmail}
                        </Text>
                        <Div d="flex" justify="center" m={{ t: "1rem" }}>
                            <Button
                                bg="white"
                                textColor="gunmetal"
                                textWeight="bold"
                                textSize="subheader"
                                rounded="circle"
                                border="0"
                                h="3.5rem"
                                onClick={() => {
                                    toast(
                                        <UndoToast
                                            text="Ok, ti contatteremo a breve!"
                                            undoAction={() => {
                                                setUndoState(true);
                                                toast.dismiss();
                                            }}
                                        />,
                                        {
                                            hideProgressBar: false,
                                            onClose: () => {
                                                !undoToast && sendMessage();
                                                setUndoState(false);
                                            },
                                        }
                                    );
                                }}
                                p={{ x: "1.5rem" }}
                            >
                                Contattaci
                            </Button>
                            <motion.div whileTap={{ scale: 0.9 }}>
                                <Button
                                    bg="gunmetal"
                                    textColor="white"
                                    textSize="subheader"
                                    rounded="20px"
                                    border="0"
                                    h="3.5rem"
                                    p={{ x: "1.5rem" }}
                                    shadow="3"
                                    hoverShadow="4"
                                    onClick={handleLogout}
                                >
                                    LOGOUT
                                </Button>
                            </motion.div>
                        </Div>
                    </Card>
                    {!supplierData?.firestore?.cannotUseOldProdQuantityModal &&
                        moment(supplierData?.registeredOnAppAt).isBefore(moment("2023-06-01")) && (
                            <Card bg="white">
                                <SettingsCard />
                            </Card>
                        )}
                    <Card bg="white">
                        <SupplierOpenHourCard sendMessage={sendMessage} />
                    </Card>
                    <Card bg="white">
                        <SupplierStatsCard />
                    </Card>
                    <Card bg="white">
                        <SupplierReviewCard />
                    </Card>
                    <Card bg="white">
                        <EarnReportCard />
                    </Card>
                    {version !== "" && (
                        <Text textAlign="center" textColor="light">
                            Versione app: {version}
                        </Text>
                    )}
                    <Container>
                        <Div d="flex" align="center" justify="space-evenly">
                            <Text
                                textAlign="center"
                                textColor="light"
                                textDecor="underline"
                                onClick={() => Modal.open("termsAndConditions")}
                            >
                                Termini e condizioni
                            </Text>

                            {JSON.parse(getRemoteValue("delete_account")) && (
                                <Text
                                    textAlign="center"
                                    textColor="light"
                                    textDecor="underline"
                                    onClick={() => Modal.open("deleteAccount")}
                                >
                                    Elimina profilo
                                </Text>
                            )}
                        </Div>
                    </Container>
                </Div>
            )}
        </>
    );
}

export default HomeTab;
