import axios from "axios";
let config = require("../settings/config.js");

//axios.defaults.withCredentials = true;

const api = axios.create(config.axiosHeaders);

export const checkRedirectLogin = (responseURL) => {
    return responseURL === config.ApiUrl + "/login/";
};

export const profile = () => {
    return api.get("/profile");
};

export const sendMessageToCustomerGroup = (orderID, text) => {
    return api.post(`/contact/${orderID ?? ""}`, { text: text });
};

export const getSupplierOpenHourFromAPI = async () => {
    return await api.get("/pickUpTime?string_result=1");
};

export const partialRefuntOrderAPI = async (orderID, availabilities) => {
    return await api.post(`/orders/${orderID}`, { availabilities: availabilities, reason: "FINISHED_PRODUCT" });
};

export default { api, profile, sendMessageToCustomerGroup, checkRedirectLogin };
