import React, { memo, useContext } from "react";
import { Div, Image } from "atomize";
import { motion } from "framer-motion";
import ProductQuantityModal from "../productQuantityModal/ProductQuantityModal";
import InsertNewProductModal from "../insertNewProductModal/InsertNewProductModal";
import SetAvailabilitiesExpirationModal from "../setAvailabilitiesExpirationModal/SetAvailabilitiesExpirationModal";
import DeleteOrderModal from "../deleteOrderModal/DeleteOrderModal";
import EditProductModal from "../editProductModal/EditProductModal";
import Modal from "../atomize/Modal";
import ArchiveProdModal from "../archiveProdModal/ArchiveProdModal";
import TermsAndConditionsModal from "../termsAndConditionsModal/TermsAndConditionsModal";
import DeleteAccountModal from "../deleteAccountModal/DeleteAccountModal";
import EditOpenHourModal from "../editOpenHourModal/EditOpenHourModal";
import { authContext } from "../../providers/auth.provider";
import NewProductQuantityModal from "../newProductQuantityModal/NewProductQuantityModal";
import PartialRefundOrderModal from "../partialRefuntOrderModal/PartialRefuntOrderModal";

const SModalContainer = ({ children, isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} bg="transparent" pos="absolute" bottom="-5px" maxW="none" p="0" m="0">
            {isOpen && (
                <>
                    <motion.div
                        animate={{ y: [200, -5, 0] }}
                        transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
                        onClick={onClose}
                    >
                        <Div p={{ x: "calc(22vw - 5vh)", t: "100vh" }} pos="relative" bottom="-5rem" zIndex="-1">
                            <Image src={require("../../images/logo-no-text.png")} />
                        </Div>
                    </motion.div>
                    <motion.div
                        animate={{ y: [200, -5, 0] }}
                        transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
                    >
                        <Div
                            bg="white"
                            rounded="t-20px"
                            p={{ t: "1rem", b: "calc(1rem + 5px + env(safe-area-inset-bottom))" }}
                        >
                            {children}
                        </Div>
                    </motion.div>
                </>
            )}
        </Modal>
    );
};

function SModal({ isOpen, onClose, type, data, prev, action }) {
    const { supplierData } = useContext(authContext);
    var modalContent;
    switch (type) {
        case "prodQuantity":
            supplierData?.firestore?.cannotUseOldProdQuantityModal || supplierData?.firestore?.usesNewProdQuantityModal
                ? (modalContent = <NewProductQuantityModal closeModal={onClose} isOpen={isOpen} prev={prev} />)
                : (modalContent = <ProductQuantityModal closeModal={onClose} isOpen={isOpen} prev={prev} />);
            break;
        case "insertNewProd":
            modalContent = <InsertNewProductModal closeModal={onClose} isOpen={isOpen} defaultData={data} />;
            break;
        case "editProd":
            modalContent = <EditProductModal closeModal={onClose} isOpen={isOpen} />;
            break;
        case "archiveProd":
            modalContent = <ArchiveProdModal closeModal={onClose} isOpen={isOpen} />;
            break;
        case "availabilitiesExpiration":
            modalContent = <SetAvailabilitiesExpirationModal closeModal={onClose} isOpen={isOpen} />;
            break;
        case "deleteOrder":
            modalContent = <DeleteOrderModal closeModal={onClose} isOpen={isOpen} orderInfo={data} />;
            break;
        case "partialRefundOrder":
            modalContent = <PartialRefundOrderModal closeModal={onClose} isOpen={isOpen} orderInfo={data} />;
            break;
        case "termsAndConditions":
            modalContent = <TermsAndConditionsModal closeModal={onClose} isOpen={isOpen} />;
            break;
        case "deleteAccount":
            modalContent = <DeleteAccountModal closeModal={onClose} isOpen={isOpen} />;
            break;
        case "editOpenHour":
            modalContent = <EditOpenHourModal closeModal={onClose} isOpen={isOpen} />;
            break;
        default:
            break;
    }

    return (
        <SModalContainer isOpen={isOpen} onClose={onClose}>
            {modalContent}
        </SModalContainer>
    );
}

export default memo(SModal);
