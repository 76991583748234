import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/analytics";
import "firebase/storage";
import "firebase/remote-config";
import { getFileBlob } from "../hooks/usePhotoGallery.js";
import { toast } from "react-toastify";
import moment from "moment";
import { loadFromStorage, saveImageToFilesystem, saveToStorage } from "./func.utils.js";
import { Storage } from "@capacitor/storage";
import { App } from "@capacitor/app";
let config = require("../settings/config.js");

const firebaseConfig = config.firebaseConfig;
firebase.initializeApp(firebaseConfig.firebaseConfigAppLocali);
const firebaseAppClienti = firebase.initializeApp(firebaseConfig.firebaseConfigAppClienti, "appClienti");

const analytics = firebase.analytics();
const firestore = firebase.firestore();
const remoteConfig = firebase.remoteConfig();
const storageAppClienti = firebaseAppClienti.storage().ref();
const firestoreAppClienti = firebaseAppClienti.firestore();
let messaging = /*(config.type === "pwa")? firebase.messaging():*/ null;
if (firebase.messaging.isSupported()) messaging = firebase.messaging();

config.isDeploy === "false" && (remoteConfig.settings.minimumFetchIntervalMillis = 360000);
remoteConfig.defaultConfig = {
    ff_reviews: false,
    delete_account: false,
    app_version: JSON.stringify({ minimum: "2.0", recommended: "2.5.2" }),
    custom_product_types: JSON.stringify({ 15: [20] }),
    onboarding_enabled: false,
};

const removeTokenFromSupplier = (supplierID, tokenID) => {
    firestore
        .collection("users_suppliers")
        .doc(supplierID)
        .collection("tokens")
        .doc(tokenID)
        .delete()
        .then(function () {
            console.info(`[FIRESTORE] Remove token ${tokenID}, from supplier ${supplierID}`);
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error removing document: ", error);
        });
};

const checkTokenAnotherSupplier = (supplierID, token) => {
    firestore
        .collectionGroup("tokens")
        .where("token", "==", token)
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                if (doc.data().supplierID !== supplierID) removeTokenFromSupplier(doc.data().supplierID, doc.id);
            });
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error check token another supplier (get conditional collectionGroup): ", error);
        });
};

const checkOldTokensSupplier = (supplierID, days) => {
    let date = new Date();
    date.setDate(date.getDate() - days);
    firestore
        .collection("users_suppliers")
        .doc(supplierID)
        .collection("tokens")
        .where("updateDate", "<=", date)
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                removeTokenFromSupplier(supplierID, doc.id);
            });
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error check old token: ", error);
        });
};

const addNewSupplier = (supplierData, infoDevice, token) => {
    firestore
        .collection("users_suppliers")
        .doc(supplierData.supplierID.toString())
        .set({
            supplierID: supplierData.supplierID,
            supplierName: supplierData.supplierName,
        })
        .then(function () {
            console.info(`[FIRESTORE] Create new instance for supplier ${supplierData.supplierID}`);
            addTokenToSupplier(supplierData, infoDevice, token);
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error writing document: ", error);
        });
};

const addTokenToSupplier = (supplierData, infoDevice, token) => {
    firestore
        .collection("users_suppliers")
        .doc(supplierData.supplierID.toString())
        .collection("tokens")
        .doc(token)
        .set(
            Object.assign({}, infoDevice, {
                token: token,
                supplierID: supplierData.supplierID.toString(),
                creationDate: firebase.firestore.Timestamp.now(),
                updateDate: firebase.firestore.Timestamp.now(),
            })
        )
        .then(function () {
            console.info(`[FIRESTORE] Token ${token} successfully added, for supplier ${supplierData.supplierID}!`);
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error add token: ", error);
        });
};

const updateSupplierTokens = (supplierID, tokenID, newData) => {
    firestore
        .collection("users_suppliers")
        .doc(supplierID)
        .collection("tokens")
        .doc(tokenID)
        .update(newData, { merge: true })
        .then(function () {
            console.info(`[FIRESTORE] Token ${tokenID} successfully update, for supplier ${supplierID}!`);
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error update token: ", error);
        });
};

export const getSupplierDataFromFirestore = async (supplierID) => {
    const supplierDataFromStorage = await loadFromStorage("suppDoc");

    if (supplierDataFromStorage?.suppDoc && moment().diff(moment(supplierDataFromStorage?.timestamp), "days") < 7)
        return supplierDataFromStorage.suppDoc;
    return firestore
        .collection("users_suppliers")
        .doc(supplierID.toString())
        .get()
        .then(async (suppDoc) => {
            if (suppDoc.exists) {
                await saveToStorage("suppDoc", { suppDoc: suppDoc.data(), timestamp: moment() });
                return suppDoc.data();
            }
        })
        .catch((error) => console.error(`[FIRESTORE] Error get supplier ${supplierID} data:`, error));
};

export const setSupplierDataFromFirestore = async (supplierID, newSupplierData) => {
    const supplierDataFromStorage = await loadFromStorage("suppDoc");

    return firestore
        .collection("users_suppliers")
        .doc(supplierID.toString())
        .set(newSupplierData, { merge: true })
        .then(async () => {
            let newSupplierDoc = Object.assign(supplierDataFromStorage.suppDoc, newSupplierData);
            await saveToStorage("suppDoc", { suppDoc: newSupplierDoc, timestamp: moment() });
            console.info(`Supplier ${supplierID} doc updated successfully`);
        })
        .catch((error) => console.error(`setSupplierDataFromFirestore for supplier ${supplierID}`, error));
};

const checkSupplierToken = async (supplierData, token) => {
    let infoDevice = await App.getInfo();
    checkOldTokensSupplier(supplierData.supplierID.toString(), 60);
    checkTokenAnotherSupplier(supplierData.supplierID.toString(), token);
    const refSupplier = firestore.collection("users_suppliers").doc(supplierData.supplierID.toString());
    refSupplier
        .get()
        .then((supDoc) => {
            if (supDoc.exists) {
                refSupplier
                    .collection("tokens")
                    .doc(token)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            let tmpData = doc.data();
                            if (tmpData.uuid !== infoDevice.id || tmpData.appVersion !== infoDevice.version) {
                                // same token different device or app version
                                tmpData.appVersion !== infoDevice.version
                                    ? console.info(
                                          `[FIRESTORE] Update app version from ${tmpData.appVersion} to ${infoDevice.version}, for supplier ${supplierData.supplierID}`
                                      )
                                    : console.info(
                                          `[FIRESTORE] Update device info per supplier ${supplierData.supplierID}`
                                      );
                                updateSupplierTokens(
                                    supplierData.supplierID.toString(),
                                    doc.id,
                                    Object.assign(doc.data(), infoDevice, {
                                        updateDate: firebase.firestore.Timestamp.now(),
                                    })
                                );
                            }
                        } else {
                            // supplier exist but token not stored
                            addTokenToSupplier(supplierData, infoDevice, token);
                        }
                    })
                    .catch(function (error) {
                        console.error("[FIRESTORE] Error get token: ", error);
                    });
            } else {
                addNewSupplier(supplierData, infoDevice, token);
            }
        })
        .catch(function (error) {
            console.error("[FIRESTORE] Error get supplier doc: ", error);
        });
};

export const getArchivedProducts = async (supplierID) => {
    const ret = await Storage.get({ key: "archivedProducts" });
    const archivedProductsFromStorage = JSON.parse(ret.value);

    if (
        archivedProductsFromStorage?.archivedProducts &&
        moment().diff(moment(archivedProductsFromStorage?.timestamp), "days") < 7
    )
        return archivedProductsFromStorage.archivedProducts;
    return firestore
        .collection("users_suppliers")
        .doc(supplierID.toString())
        .collection("archived_products")
        .get()
        .then(async (archived) => {
            let archivedProducts = {};
            archived.docs.forEach((product) => {
                if (product.data().archived)
                    archivedProducts[product.data().productName.toLowerCase()] = product.id.toString();
            });
            await Storage.set({
                key: "archivedProducts",
                value: JSON.stringify({ archivedProducts: archivedProducts, timestamp: moment() }),
            });
            return archivedProducts;
        })
        .catch((error) => console.error(`[FIRESTORE] Error get supplier ${supplierID} archived products: :`, error));
};

export const checkArchivedProducts = async (productData, action) => {
    if (action === "restore") return updateArchivedProduct(productData, action);
    return firestore
        .collection("users_suppliers")
        .doc(productData.supplierID.toString())
        .collection("archived_products")
        .doc(productData.productID.toString())
        .get()
        .then((doc) => {
            if (!doc?.exists) {
                // product to be archived
                return archiveProduct(productData);
            } else {
                // product already archived
                return updateArchivedProduct(productData, action);
            }
        })
        .catch(function (error) {
            console.error(`[FIRESTORE] Error check supplier ${productData.supplierID} archived products: `, error);
        });
};

export const archiveProduct = async (productData) => {
    const ret = await Storage.get({ key: "archivedProducts" });
    let archivedProductsFromStorage = JSON.parse(ret.value);

    return firestore
        .collection("users_suppliers")
        .doc(productData.supplierID.toString())
        .collection("archived_products")
        .doc(productData.productID.toString())
        .set({
            creationDate: firebase.firestore.Timestamp.now(),
            updateDate: firebase.firestore.Timestamp.now(),
            archived: true,
            ...productData,
        })
        .then(async () => {
            console.info(
                `[FIRESTORE] Archived product ${productData.productID} for supplier ${productData.supplierID}`
            );
            archivedProductsFromStorage.archivedProducts[productData.productName.toLowerCase()] = productData.productID;
            await Storage.set({
                key: "archivedProducts",
                value: JSON.stringify({
                    archivedProducts: archivedProductsFromStorage.archivedProducts,
                    timestamp: moment(),
                }),
            });
            return archivedProductsFromStorage.archivedProducts;
        })
        .catch((error) =>
            console.error(
                `Error archive product ${productData.productID} for supplier ${productData.supplierID}: `,
                error
            )
        );
};

export const updateArchivedProduct = async (productData, action) => {
    const ret = await Storage.get({ key: "archivedProducts" });
    let archivedProductsFromStorage = JSON.parse(ret.value);

    return firestore
        .collection("users_suppliers")
        .doc(productData.supplierID.toString())
        .collection("archived_products")
        .doc(productData.productID.toString())
        .update(
            {
                updateDate: firebase.firestore.Timestamp.now(),
                archived: action === "archive" ? true : false,
            },
            { merge: true }
        )
        .then(async () => {
            console.info(
                `[FIRESTORE] Updated product ${productData.productID} for supplier ${productData.supplierID}, now is ${action}d`
            );
            if (action === "restore")
                delete archivedProductsFromStorage.archivedProducts[productData.productName.toLowerCase()];
            else
                archivedProductsFromStorage.archivedProducts[productData.productName.toLowerCase()] =
                    productData.productID;
            await Storage.set({
                key: "archivedProducts",
                value: JSON.stringify({
                    archivedProducts: archivedProductsFromStorage.archivedProducts,
                    timestamp: moment(),
                }),
            });
            return archivedProductsFromStorage.archivedProducts;
        })
        .catch((error) =>
            console.error(`[FIRESTORE] Error updating archived product ${productData.productID}:`, error)
        );
};

export const getUserSubscribedCount = async (supplierID) => {
    let userSubscribedFromStorage = await loadFromStorage("userSubscribed");

    if (
        userSubscribedFromStorage?.[supplierID]?.userSubscribed &&
        moment().diff(moment(userSubscribedFromStorage?.[supplierID]?.timestamp), "days") < 1
    )
        return userSubscribedFromStorage?.[supplierID]?.userSubscribed;
    else {
        let userSubscribedCount = 0;
        return firestoreAppClienti
            .collection("suppliers")
            .doc(supplierID.toString())
            .collection("users_subscribed")
            .get()
            .then(async (docs) => {
                docs.forEach((doc) => userSubscribedCount++);
                await saveToStorage("userSubscribed", {
                    [supplierID]: { userSubscribed: userSubscribedCount, timestamp: moment() },
                });
                return userSubscribedCount;
            })
            .catch((error) =>
                console.error(`[FIRESTORE] Error getUserSubscribedCount for supplier ${supplierID}:`, error)
            );
    }
};

export const getProductImageFromFirebase = (productID) => {
    const productRef = storageAppClienti.child("products").child(productID?.toString());
    return getDownloadURL(productRef);
};

export const addProductImage = async (image, productID, update, callback) => {
    const path = `products/${productID?.toString()}/${productID?.toString()}.png`;
    const productRef = storageAppClienti.child(path);

    return new Promise((resolve) =>
        getFileBlob(image, async (blob) => {
            productRef
                .put(blob)
                .then((snapshot) => {
                    update && toast.success("Prodotto aggiornato!", { position: "top-center" });
                    console.log(`[STORAGE] Image for product ${productID} successfully uploaded`);
                    saveImageToFilesystem(URL.createObjectURL(blob), "products", productID)
                        .then(() => callback && callback())
                        .catch((error) => console.error("addProductImage > saveImageToFilesystem", error));
                })
                .catch((error) => {
                    toast.error("Immagine troppo pesante, non superare i 5MB!", { position: "top-center" });
                    console.error(`[STORAGE] Image for product ${productID} not uploaded`, error);
                })
                .finally(() => resolve());
        })
    );
};

export const getDownloadURL = (ref) => {
    return ref
        .listAll()
        .then((photo) => {
            if (photo.items.length > 0) {
                const image = ref.child(photo.items[0].name);
                return image
                    .getDownloadURL()
                    .then((url) => {
                        return url;
                    })
                    .catch((error) => console.warn("[FIREBASE] getSupplierImage > getDownloadURL:", error));
            } else throw "No image found";
        })
        .catch((error) => {
            throw error;
        });
};

export const getOrdersReviews = async (supplierID) => {
    let reviewedOrdersFromStorage = await loadFromStorage("reviewedOrders");

    if (
        reviewedOrdersFromStorage?.[supplierID]?.reviewedOrders &&
        moment().diff(moment(reviewedOrdersFromStorage?.[supplierID]?.timestamp), "days") < 7
    )
        return reviewedOrdersFromStorage?.[supplierID]?.reviewedOrders;
    return firestoreAppClienti
        .collection("suppliers")
        .doc(supplierID?.toString())
        .collection("orders_reviews")
        .get()
        .then(async (ordersWithReview) => {
            let reviewedOrders = {};
            ordersWithReview.docs.forEach((review) => {
                reviewedOrders[review.id] = review.data();
            });
            await saveToStorage("reviewedOrders", {
                [supplierID]: { reviewedOrders: reviewedOrders, timestamp: moment() },
            });
            return reviewedOrders;
        })
        .catch((error) => console.error(`[FIRESTORE] Error get orders review for supplier ${supplierID}`, error));
};

export const setDeliveryHourToBeConfirmed = async (pickUpHours, supplierID) => {
    const refSupplier = firestore.collection("users_suppliers").doc(supplierID.toString());
    refSupplier
        .update({
            newPickUpHours: {
                pickUpHours: pickUpHours,
                confirmed: false,
            },
        })
        .then(() => console.info(`[FIRESTORE] supplier ${supplierID} asked for new deliveryHour`))
        .catch((error) => console.error("[FIRESTORE] Error writing document:", error));
};

export const getDeliveryHourToBeConfirmed = async (supplierID) => {
    const refSupplier = firestore.collection("users_suppliers").doc(supplierID.toString());
    return refSupplier.get().then((doc) => {
        if (doc.exists) return doc.data().newPickUpHours;
        else return null;
    });
};

export const addDeliveryHourToBeConfirmedListener = async (supplierID, callback) => {
    const refSupplier = firestore.collection("users_suppliers").doc(supplierID.toString());
    refSupplier.onSnapshot((doc) => {
        if (doc.exists) callback(doc.data().newPickUpHours?.confirmed);
    });
};

export const getSupplierReview = async (supplierID) => {
    let supplierReviewFromStorage = await loadFromStorage("supplierReview");

    if (
        supplierReviewFromStorage?.[supplierID]?.supplierReview &&
        moment().diff(moment(supplierReviewFromStorage?.[supplierID]?.timestamp), "days") < 7
    )
        return supplierReviewFromStorage?.[supplierID]?.supplierReview;
    return firestoreAppClienti
        .collection("suppliers")
        .doc(supplierID?.toString())
        .get()
        .then(async (data) => {
            if (data.exists) {
                let evaluation = (data.data().reviews.positiveReviews * 5) / data.data().reviews.totalReviews;
                let supplierReview = { evaluation: evaluation, totalReviews: data.data().reviews.totalReviews };
                await saveToStorage("supplierReview", {
                    [supplierID]: { supplierReview: supplierReview, timestamp: moment() },
                });
                return supplierReview;
            }
        })
        .catch((error) => console.error(`[FIRESTORE] Error get review info for supplier ${supplierID}`, error));
};

export const getProdsInternalID = async (supplierID) => {
    const prodsInternalIDFromStorage = await loadFromStorage("prodsInternalID");

    if (
        prodsInternalIDFromStorage?.prodsInternalID &&
        moment().diff(moment(prodsInternalIDFromStorage?.timestamp), "hours") < 6
    )
        return prodsInternalIDFromStorage.prodsInternalID;
    return firestore
        .collection("users_suppliers")
        .doc(supplierID.toString())
        .collection("products_internal_id")
        .get()
        .then(async (productsID) => {
            let prodsInternalID = {};
            productsID.docs.forEach((product) => {
                prodsInternalID[product.data().productID] = product.data().productInternalID;
            });
            console.log(prodsInternalID);
            await saveToStorage("prodsInternalID", { prodsInternalID: prodsInternalID, timestamp: moment() });
            return prodsInternalID;
        })
        .catch((error) =>
            console.error(`[FIRESTORE] Error get internal products id for supplier ${supplierID}:`, error)
        );
};

export const insertProdInternalID = async (productData, prodInternalID, refreshFunction) => {
    let prodsInternalIDFromStorage = await loadFromStorage("prodsInternalID");

    return firestore
        .collection("users_suppliers")
        .doc(productData.supplierID.toString())
        .collection("products_internal_id")
        .doc(productData.productID.toString())
        .set({
            creationDate: firebase.firestore.Timestamp.now(),
            updateDate: firebase.firestore.Timestamp.now(),
            productInternalID: prodInternalID,
            ...productData,
        })
        .then(async () => {
            console.info(
                `[FIRESTORE] Added ${productData.productID} internal id ('${prodInternalID}') for supplier ${productData.supplierID}`
            );
            prodsInternalIDFromStorage.prodsInternalID[productData.productID] = prodInternalID;
            await saveToStorage("prodsInternalID", {
                prodsInternalID: prodsInternalIDFromStorage.prodsInternalID,
                timestamp: moment(),
            });
            refreshFunction();
            return prodsInternalIDFromStorage.prodsInternalID;
        })
        .catch((error) =>
            console.error(
                `Error add product ${productData.productID} internal id ('${prodInternalID}') for supplier ${productData.supplierID}:`,
                error
            )
        );
};

export const updateProdInternalID = async (productData, prodInternalID, refreshFunction) => {
    let prodsInternalIDFromStorage = await loadFromStorage("prodsInternalID");

    return firestore
        .collection("users_suppliers")
        .doc(productData.supplierID.toString())
        .collection("products_internal_id")
        .doc(productData.productID.toString())
        .update({
            updateDate: firebase.firestore.Timestamp.now(),
            productInternalID: prodInternalID,
        })
        .then(async () => {
            console.info(
                `[FIRESTORE] Updated ${productData.productID} internal id ('${prodInternalID}') for supplier ${productData.supplierID}`
            );
            prodsInternalIDFromStorage.prodsInternalID[productData.productID] = prodInternalID;
            await saveToStorage("prodsInternalID", {
                prodsInternalID: prodsInternalIDFromStorage.prodsInternalID,
                timestamp: moment(),
            });
            refreshFunction();
            return prodsInternalIDFromStorage.prodsInternalID;
        })
        .catch((error) =>
            console.error(
                `Error update product ${productData.productID} internal id ('${prodInternalID}') for supplier ${productData.supplierID}:`,
                error
            )
        );
};

export default {
    analytics,
    firestore,
    messaging,
    remoteConfig,
    checkTokenAnotherSupplier,
    addNewSupplier,
    getSupplierDataFromFirestore,
    checkSupplierToken,
    removeTokenFromSupplier,
};
