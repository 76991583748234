import { Button, Col, Div, Row, Text } from "atomize";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UndoToast from "../toast/UndoToast";
import moment from "moment";
import { motion } from "framer-motion";
import { ordersContext } from "../../providers/orders.provider";
import { modalContext } from "../../providers/modal.provider";
import theme from "../../appearance/theme";
import { productsContext } from "../../providers/products.provider";

export const ProdRow = (props) => {
    return (
        <Div d="flex" align="center" m={{ y: "0.25rem" }}>
            <Text
                d="flex"
                justify="center"
                align="center"
                w="1.5rem"
                h="1.5rem"
                textSize="subheader"
                textWeight="bold"
                p="1rem"
                rounded="20px"
                bg={props.ordersType ? "gunmetal_blur" : props.prodInfo?.soldAt <= 0.5 ? "primary_blur" : "accent_blur"}
            >
                {props.prodInfo?.quantity}
            </Text>
            <Div m={{ l: "0.75rem" }} style={{ flexBasis: "80%" }}>
                <Text textSize="subheader">{props.prodInfo?.productName}</Text>
                {props.prodInternalID && (
                    <Text
                        textSize="paragraph"
                        w="fit-content"
                        p={{ x: "0.5rem" }}
                        textColor="dark"
                        bg="gunmetal_light"
                        rounded="circle"
                        textAlign="right"
                    >
                        {props.prodInternalID}
                    </Text>
                )}
            </Div>
        </Div>
    );
};

function OrderBody(props) {
    const [orderIssue, setOrderIssue] = useState(false);

    const { ordersType, editOrdersFunctions, setSelectedOrder } = useContext(ordersContext);
    const { productsEntries } = useContext(productsContext);
    const Modal = useContext(modalContext);

    let undoPickUp = false;
    const setUndoState = (val) => {
        undoPickUp = val;
    };

    useEffect(() => {
        !props.isOpen && setOrderIssue(false);
    }, [props.isOpen]);

    return (
        <>
            {props.isOpen && (
                <motion.section
                    style={{ overflow: "hidden" }}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                        duration: 0.4,
                        ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                >
                    <Div bg="white" rounded="20px" p={{ x: "1rem", y: "0.75rem" }} m={{ t: "0.5rem" }}>
                        <Div m={{ b: "0.5rem" }}>
                            {props.orderInfo.menu &&
                                props.orderInfo.menu.map((prodInfo) => (
                                    <ProdRow
                                        key={`prodInfo-${props.orderInfo.orderCod}-${prodInfo.productID}`}
                                        ordersType={ordersType}
                                        prodInternalID={productsEntries.internalID[prodInfo.productID]}
                                        prodInfo={prodInfo}
                                    />
                                ))}
                        </Div>
                        {props.orderInfo?.deliveryInfo?.orderNote && (
                            <Div m={{ t: "0.5rem" }}>
                                <hr style={{ backgroundColor: theme.colors.gunmetal_blur }} />
                                <Div>
                                    <Text tag="span" textSize="subheader" textWeight="bold">
                                        Il cliente dice:{" "}
                                    </Text>
                                    <Text tag="span" textSize="subheader">
                                        {props.orderInfo?.deliveryInfo?.orderNote}
                                    </Text>
                                </Div>
                            </Div>
                        )}
                    </Div>
                    {!ordersType && (
                        <Div m={{ t: "0.5rem" }}>
                            <Row p={{ y: "0.5rem" }} m={{ x: "0.5rem" }} align="center">
                                <Col p="0">
                                    <Button
                                        bg="transparent"
                                        m="auto"
                                        h="auto"
                                        onClick={() => Modal.open("deleteOrder", props.orderInfo)}
                                    >
                                        <Text textColor="gunmetal" textWeight="bold" textSize="subheader">
                                            Problema con l'ordine
                                        </Text>
                                    </Button>
                                </Col>
                                <Col p="0">
                                    <motion.div whileTap={{ scale: 0.9 }}>
                                        <Button
                                            onClick={() => {
                                                toast.dismiss();
                                                toast(
                                                    <UndoToast
                                                        text={"Ordine #" + props.orderInfo.orderCod + " ritirato!"}
                                                        undoAction={() => {
                                                            props.setPickUpState(false);
                                                            setUndoState(true);
                                                            toast.dismiss();
                                                            setSelectedOrder(props.orderInfo.orderCod);
                                                        }}
                                                    />,
                                                    {
                                                        hideProgressBar: false,
                                                        onOpen: () => {
                                                            props.setPickUpState(true);
                                                            setSelectedOrder(null);
                                                        },
                                                        onClose: () => {
                                                            !undoPickUp &&
                                                                editOrdersFunctions.setDelivered(
                                                                    props.orderInfo.orderCod
                                                                );
                                                            setUndoState(false);
                                                        },
                                                    }
                                                );
                                            }}
                                            disabled={
                                                props.orderInfo?.deliveryInfo?.deliveryHour?.date
                                                    ? moment(props.orderInfo.deliveryInfo?.deliveryHour?.date).isAfter()
                                                    : false
                                            }
                                            bg="gunmetal"
                                            h="3.5rem"
                                            m="auto"
                                            rounded="20px"
                                        >
                                            <Text textColor="white" textSize="subheader">
                                                Ritirato!
                                            </Text>
                                        </Button>
                                    </motion.div>
                                </Col>
                            </Row>
                            {orderIssue && (
                                <motion.section
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 },
                                    }}
                                    transition={{
                                        duration: 0.3,
                                        ease: [0.04, 0.62, 0.23, 0.98],
                                    }}
                                >
                                    <Row p={{ t: "0.5rem" }}>
                                        <Col>
                                            <Button
                                                bg="transparent"
                                                m="auto"
                                                p="0"
                                                onClick={() =>
                                                    editOrdersFunctions.sendMessageOrder(props.orderInfo.orderCod, true)
                                                }
                                            >
                                                <Text textColor="danger800" textWeight="bold" textSize="body">
                                                    Contattaci!
                                                </Text>
                                            </Button>
                                        </Col>
                                        {!moment(
                                            props.orderInfo?.deliveryInfo?.deliveryHour?.date +
                                                " " +
                                                props.orderInfo?.deliveryInfo?.deliveryHour?.deliveryHourEnd
                                        ).isBefore() && (
                                            <Col>
                                                <motion.div whileTap={{ scale: 0.9 }}>
                                                    <Button
                                                        bg="danger800"
                                                        m="auto"
                                                        onClick={() => Modal.open("deleteOrder", props.orderInfo)}
                                                    >
                                                        <Text textColor="white" textWeight="bold" textSize="body">
                                                            Annulla l'ordine
                                                        </Text>
                                                    </Button>
                                                </motion.div>
                                            </Col>
                                        )}
                                    </Row>
                                </motion.section>
                            )}
                        </Div>
                    )}
                </motion.section>
            )}
        </>
    );
}

export default OrderBody;
