import React, { useEffect, useState } from "react";
import ApiService from "../services/api.service";
import "react-toastify/dist/ReactToastify.css";
import config from "../settings/config";
import firebaseService from "../services/firebase.service";
import { initSentry } from "../services/func.utils";
import * as Sentry from "@sentry/react";
import apiService from "../services/api.service";
import moment from "moment";
import { toast } from "react-toastify";
import { Storage } from "@capacitor/storage";
import { SplashScreen } from "@capacitor/splash-screen";

export const authContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [logged, setLog] = useState(null);
    const [supplierData, setSupplierData] = useState(null);
    const [archivedProducts, setArchivedProducts] = useState({});
    const [tab, setTab] = useState("products"); // home | products | orders
    const [retrieveRemoteConfig, setRetrieveRemoteConfig] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);

    const [expiringDate, setExpiringDate] = useState(moment().format("YYYY-MM-DD"));
    const [expiration, setExpiration] = useState(0); //indice che scorre le expirations
    const [expirations, setExpirations] = useState(null);
    const [loadingExpirations, setLoadingExpirationState] = useState(false);

    const initRemoteConfig = () => {
        firebaseService.remoteConfig
            .fetchAndActivate()
            .then(() => initSentry())
            .catch((error) => console.warn("Error fetch remoteConfig:", error))
            .finally(() => setRetrieveRemoteConfig(true));
    };

    useEffect(() => {
        config["isDeploy"] && setUserLogging();
        Storage.migrate();
        checkSession();
        initRemoteConfig();
    }, []);

    useEffect(() => {
        !authLoading && retrieveRemoteConfig && SplashScreen.hide();
    }, [retrieveRemoteConfig, authLoading]);

    useEffect(() => {
        setExpiration(0);
    }, [expiringDate]);

    const setUserLogging = async () => {
        const ret = await Storage.get({ key: "supplierData" });
        const user = JSON.parse(ret.value);
        if (user) {
            Sentry.setUser({
                email: user.supplierEmail,
                id: user.supplierID,
                name: user.supplierName,
            });
            setSupplierData((prevState) => ({ ...prevState, ...user }));
        }
    };

    const checkSession = () => {
        ApiService.profile()
            .then((response) => {
                if (ApiService.checkRedirectLogin(response.request.responseURL)) {
                    setLog(false);
                } else {
                    setLog(true);
                }
            })
            .catch((error) => {
                console.error("[API] Check session (get profile) ", error);
                setLog(false);
            })
            .finally(() => {
                setAuthLoading(false);
            });
    };

    const getNextExpiration = async () => {
        if (!expirations?.[expiringDate]) {
            setLoadingExpirationState(true);
            await apiService.api
                .get(`/pickUpTime?next=1&after_datetime=${expiringDate}&limit=5`)
                .then((response) => {
                    if (apiService.checkRedirectLogin(response.request.responseURL)) {
                        setLog(false);
                    } else {
                        const expiringDateTimeList = response.data.filter((expiringEntry) =>
                            moment(expiringDate).isSame(
                                moment(moment(expiringEntry.expiringDateTime).format("YYYY-MM-DD")),
                                "day"
                            )
                        );
                        setExpirations((prevState) => ({
                            ...prevState,
                            [expiringDate]: expiringDateTimeList,
                        }));
                    }
                })
                .catch((error) => {
                    console.error("[API] Get next expiration ", error);
                    toast.error(
                        "C'è stato un problema ad ottenere gli orari di ritiro. MANDA UN RICHIESTA DI ASSISTENZA!"
                    );
                })
                .finally(() => setLoadingExpirationState(false));
        }
    };

    return (
        <authContext.Provider
            value={{
                logged,
                supplierData,
                tab,
                archivedProducts,
                expiringDate,
                expiration,
                expirations,
                loadingExpirations,
                setLog,
                setSupplierData,
                setTab,
                setArchivedProducts,
                getNextExpiration,
                setExpiration,
                setExpiringDate,
            }}
        >
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;
