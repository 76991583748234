import React, { useContext, useState } from "react";
import { Div, Text, Row, Col, Button } from "atomize";
import { toast } from "react-toastify";
import { partialRefuntOrderAPI } from "../../services/api.service";
import { ordersContext } from "../../providers/orders.provider";
import { motion } from "framer-motion";
import { productsContext } from "../../providers/products.provider";
import { modalContext } from "../../providers/modal.provider";
import { ProdRow } from "../orderBody/OrderBody";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BoldText from "../boldText/BoldText";

function PartialRefundOrderModal(props) {
    const [selectedMissingAvailability, setSelectedMissingAvailability] = useState(null);
    const [displayAvailabilities, setDisplayAvailabilities] = useState(
        JSON.parse(JSON.stringify(props.orderInfo?.menu))
    );
    const [quantity, setQuantity] = useState(0);
    const [missingAvailabilities, setMissingAvailabilities] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { ordersType, getOrdersActive, partialRefundOrder } = useContext(ordersContext);
    const { productsEntries } = useContext(productsContext);
    const Modal = useContext(modalContext);

    return (
        <>
            <Div p={{ x: "1rem", t: "0.5rem" }}>
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="title" textWeight="bold">
                    {!selectedMissingAvailability ? "Quali prodotti mancano?" : "Ok, risolviamo!"}
                </Text>

                <Div p={{ t: "0.5rem", l: "0.5rem" }}>
                    {!selectedMissingAvailability && (
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <Text textSize="subheader" p={{ b: "0.5rem" }}>
                                Tocca il prodotto mancante
                            </Text>
                            <Div m={{ b: "0.5rem" }}>
                                {displayAvailabilities?.map((prodInfo, i) => (
                                    <motion.div
                                        key={i}
                                        whileTap={{ scale: 0.9 }}
                                        onClick={() => {
                                            setSelectedMissingAvailability({ availability: prodInfo, index: i });
                                            setQuantity(prodInfo.quantity);
                                        }}
                                    >
                                        <ProdRow
                                            ordersType={ordersType}
                                            prodInternalID={productsEntries.internalID[prodInfo.productID]}
                                            prodInfo={prodInfo}
                                        />
                                    </motion.div>
                                ))}
                            </Div>
                        </motion.div>
                    )}
                    {selectedMissingAvailability && (
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <Text m={{ y: "0.5rem" }} textAlign="center" textWeight="bold" textSize="subheader">
                                Quante porzioni di{" "}
                                <BoldText textColor="gunmetal">
                                    {selectedMissingAvailability.availability.productName}
                                </BoldText>{" "}
                                rimangono?
                            </Text>
                            <Div d="flex" align="center" w="fit-content" m={{ x: "auto", b: "1rem" }}>
                                <Button
                                    bg={
                                        displayAvailabilities.length > 1
                                            ? quantity === 0
                                                ? "light"
                                                : "gunmetal"
                                            : quantity === 1
                                            ? "light"
                                            : "gunmetal"
                                    }
                                    h="3rem"
                                    w="3rem"
                                    m={{ x: "1rem" }}
                                    rounded="circle"
                                    textColor="white"
                                    onClick={() => {
                                        displayAvailabilities.length > 1
                                            ? quantity > 0 && setQuantity(quantity - 1)
                                            : quantity > 1 && setQuantity(quantity - 1);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMinus} size="2x" color="white" />
                                </Button>
                                <Text textSize="display1" m={{ x: "1rem" }}>
                                    {quantity}
                                </Text>
                                <Button
                                    pos="relative"
                                    zIndex="0"
                                    bg={
                                        quantity === props.orderInfo?.menu[selectedMissingAvailability?.index].quantity
                                            ? "light"
                                            : "gunmetal"
                                    }
                                    h="3rem"
                                    w="3rem"
                                    m={{ x: "1rem" }}
                                    rounded="circle"
                                    textColor="white"
                                    onClick={() => {
                                        quantity < props.orderInfo?.menu[selectedMissingAvailability?.index].quantity &&
                                            setQuantity(quantity + 1);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} size="2x" color="white" />
                                </Button>
                            </Div>
                        </motion.div>
                    )}
                </Div>
            </Div>
            <Row m={{ x: "2rem" }}>
                <Col p="0">
                    <Button
                        onClick={
                            selectedMissingAvailability
                                ? () => setSelectedMissingAvailability(null)
                                : () => Modal.open("deleteOrder", props.orderInfo)
                        }
                        bg="white"
                        h="3.5rem"
                        m="auto"
                        textColor="gunmetal"
                    >
                        <Text textSize="title">Indietro</Text>
                    </Button>
                </Col>
                <Col p="0">
                    <Button
                        onClick={
                            selectedMissingAvailability
                                ? () => {
                                      setDisplayAvailabilities(
                                          displayAvailabilities.map((avail) => {
                                              if (
                                                  avail.availabilityID ===
                                                  selectedMissingAvailability.availability.availabilityID
                                              )
                                                  avail.quantity = quantity;
                                              return avail;
                                          })
                                      );
                                      if (missingAvailabilities.length > 0) {
                                          const missingAvailIndex = missingAvailabilities.findIndex(
                                              (missingAvail) =>
                                                  missingAvail.availabilityID ===
                                                  selectedMissingAvailability.availability.availabilityID
                                          );
                                          if (missingAvailIndex === -1)
                                              setMissingAvailabilities((prevState) => [
                                                  ...prevState,
                                                  {
                                                      availabilityID:
                                                          selectedMissingAvailability.availability.availabilityID,
                                                      quantity:
                                                          props.orderInfo?.menu[selectedMissingAvailability?.index]
                                                              .quantity - quantity,
                                                  },
                                              ]);
                                          else {
                                              let tmpMissingAvailabilities = JSON.parse(
                                                  JSON.stringify(missingAvailabilities)
                                              );
                                              if (
                                                  props.orderInfo?.menu[selectedMissingAvailability?.index].quantity -
                                                      quantity ===
                                                  0
                                              )
                                                  tmpMissingAvailabilities.splice(missingAvailIndex, 1);
                                              else
                                                  tmpMissingAvailabilities[missingAvailIndex].quantity =
                                                      props.orderInfo?.menu[selectedMissingAvailability?.index]
                                                          .quantity - quantity;
                                              setMissingAvailabilities(tmpMissingAvailabilities);
                                          }
                                      } else
                                          setMissingAvailabilities([
                                              {
                                                  availabilityID:
                                                      selectedMissingAvailability.availability.availabilityID,
                                                  quantity:
                                                      props.orderInfo?.menu[selectedMissingAvailability?.index]
                                                          .quantity - quantity,
                                              },
                                          ]);
                                      setSelectedMissingAvailability(null);
                                  }
                                : () => {
                                      setLoading(true);
                                      partialRefuntOrderAPI(props.orderInfo.orderCod, missingAvailabilities)
                                          .then(() => {
                                              toast.success("Ordine aggiornato e cliente rimborsato!");
                                              partialRefundOrder(displayAvailabilities); // aggiornamento locale
                                              props.closeModal();
                                          })
                                          .catch((error) => {
                                              toast.error("Qualcosa non va, avviso i ragazzi");
                                              console.error(error.response?.data?.message);
                                          })
                                          .finally(() => setLoading(false));
                                  }
                        }
                        bg="gunmetal"
                        shadow="3"
                        h="3.5rem"
                        p={{ x: "1.5rem" }}
                        rounded="20px"
                        textColor="white"
                        m={{ x: "auto" }}
                        disabled={quantity === selectedMissingAvailability?.availability.quantity || isLoading}
                    >
                        <Text textSize="title">{!selectedMissingAvailability ? "Conferma" : "Avanti"}</Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default PartialRefundOrderModal;
